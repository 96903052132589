<mat-dialog-content>
  <mat-horizontal-stepper
    #horizontalStepper
    linear="true"
    class="mat-elevation-z2"
    fxShow="false"
    fxShow.gt-sm
  >
    <mat-step [stepControl]="billingForm" label="Billing address">
      <form [formGroup]="billingForm">
        <div fxLayout="row wrap">
          <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Saved Addresses</mat-label>
              <mat-select (selectionChange)="updateForm($event.value)">
                <mat-option
                  *ngFor="let userAddress of userAddresses"
                  [value]="userAddress.id"
                >
                  <b>{{ userAddress.address }}</b
                  >, {{ userAddress.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Name"
                formControlName="name"
                required
              />
              <mat-error *ngIf="billingForm.controls.name.errors?.required"
                >Name is required</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input
                matInput
                placeholder="Email"
                formControlName="email"
                required
              />
              <mat-error *ngIf="billingForm.controls.email.errors?.required"
                >Email is required</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Phone</mat-label>
              <input
                matInput
                placeholder="Phone"
                formControlName="phone"
                required
              />
              <mat-error *ngIf="billingForm.controls.phone.errors?.required"
                >Phone is required</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Country</mat-label>
              <mat-select
                placeholder="Country"
                formControlName="country"
                required
              >
                <mat-option *ngFor="let country of countries" [value]="country">
                  {{ country.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="billingForm.controls.country.errors?.required"
                >Country is required</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>City</mat-label>
              <input
                matInput
                placeholder="City"
                formControlName="city"
                required
              />
              <mat-error *ngIf="billingForm.controls.city.errors?.required"
                >City is required</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State/Province</mat-label>
              <input
                matInput
                placeholder="State/Province"
                formControlName="state"
              />
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Zip/Postal Code</mat-label>
              <input
                matInput
                placeholder="Zip/Postal Code"
                formControlName="zip"
                required
              />
              <mat-error *ngIf="billingForm.controls.zip.errors?.required"
                >Zip/Postal Code is required</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="100" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Address</mat-label>
              <input
                matInput
                placeholder="Address (street, apartment, suite, unit etc.)"
                formControlName="address"
                required
              />
              <mat-error *ngIf="billingForm.controls.address.errors?.required"
                >Address is required</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-2 p-1">
          <button
            mat-mini-fab
            color="primary"
            matTooltip="Next"
            matTooltipPosition="after"
            (click)="nextStepper(1)"
          >
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="paymentForm" label="Payment options">
      <form [formGroup]="paymentForm">
        <div
          fxLayout="row justify-content-center"
          fxLayoutAlign="center center"
        >
          <!-- <div class="col-md-4 p-1">
            <mat-card
              [class]="
                paymentMethodId == 0
                  ? 'product-item text-center withHighlight'
                  : 'product-item text-center noHighlight'
              "
              (click)="pickPaymentBox(0)"
            >
              <a class="image-link">
                <div style="height: 125px; width: auto">
                  <img
                    class="mt-3"
                    src="assets/images/tfx/payIcon.png"
                    alt=""
                    style="height: 100px; width: auto"
                  />
                </div>
                <div class="title text-truncate mt-1">Buy Now</div>
              </a>
              <p class="new-price" style="height: 50px !important">
                Pay via payment gateway with you preferred payment method.
                <br />
                (Credit card, FPX, e-Wallet, etc)
              </p>
            </mat-card>
          </div> -->
          <div class="col-md-4 p-1">
            <mat-card
              [class]="
                paymentMethodId == 1
                  ? 'product-item text-center withHighlight'
                  : 'product-item text-center noHighlight'
              "
              (click)="pickPaymentBox(1)"
            >
              <a class="image-link">
                <div style="height: 125px; width: auto">
                  <img
                    class="mt-3"
                    src="assets/images/tfx/order.jpg"
                    alt=""
                    style="height: 100px; width: auto"
                  />
                </div>
                <div class="title text-truncate mt-1">Place Order</div>
              </a>
              <p class="new-price" style="height: 50px !important">
                Place your order / booking without payment
              </p>
            </mat-card>
          </div>
          <div class="col-md-4 p-1">
            <mat-card
              [class]="
                paymentMethodId == 2
                  ? 'product-item text-center withHighlight'
                  : 'product-item text-center noHighlight'
              "
              (click)="pickPaymentBox(2)"
            >
              <a class="image-link">
                <div style="height: 125px; width: auto">
                  <img
                    class="mt-3"
                    src="assets/images/tfx/quotation.jpg"
                    alt=""
                    style="height: 100px; width: auto"
                  />
                </div>
                <div class="title text-truncate mt-1">Ask for Quotation</div>
              </a>
              <p class="new-price text-center" style="height: 50px !important">
                Request personalized pricing and product details before placing
                an order.
              </p>
            </mat-card>
          </div>
        </div>
        <div
          *ngIf="myCompanyObj?.whatsappLink?.length > 0"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="mt-1 p-0"
        >
          <a [href]="myCompanyObj?.whatsappLink" target="_blank">
            <div class="m-0 p-0" style="height: 50px; width: auto">
              <img
                class="m-0 p-0"
                src="assets/images/tfx/whatsapp.png"
                alt=""
                style="height: 50px; width: auto"
              />
            </div>
          </a>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="mt-1">
          <button
            mat-mini-fab
            (click)="backStepper()"
            color="primary"
            matTooltip="Back"
            matTooltipPosition="before"
            class="mr-1"
          >
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            matTooltip="Next"
            matTooltipPosition="after"
            (click)="nextStepper(2)"
          >
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Review Order">
      <div fxLayout="row wrap">
        <div fxFlex="100" class="p-1">
          <h3 class="text-muted">Shopping Cart</h3>
          <div class="divider"></div>
          <div class="mat-table review-order-table">
            <div class="mat-header-row">
              <div class="mat-header-cell">Product</div>
              <div class="mat-header-cell">Name</div>
              <div class="mat-header-cell">Unit Price</div>
              <div class="mat-header-cell">Quantity</div>
              <div class="mat-header-cell">Total</div>
            </div>
            <div
              *ngFor="let product of appService.Data.cartList"
              class="mat-row"
            >
              <div *ngIf="product?.productDto?.images?.length > 0" class="mat-cell">
                <img [src]="product?.productDto?.images[0]?.small" />
              </div>
              <div class="mat-cell">{{ product.name }}</div>
              <div class="mat-cell">
                RM{{ product.unitPrice | number : "1.2-2" }}
              </div>
              <div class="mat-cell text-muted">{{ product.quantity }}</div>
              <div class="mat-cell">
                RM{{ product.unitPrice * product.quantity | number : "1.2-2" }}
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="100" class="p-1">
          <h3 class="text-muted mt-2">Billing information</h3>
          <div class="divider"></div>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">Name:</p>
          <p>
            {{ billingForm.controls.name.value }}
          </p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">Email:</p>
          <p>{{ billingForm.controls.email.value }}</p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">Phone:</p>
          <p>{{ billingForm.controls.phone.value }}</p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">Country:</p>
          <p>{{ billingForm.controls.country.value["name"] }}</p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">City:</p>
          <p>{{ billingForm.controls.city.value }}</p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">State:</p>
          <p>{{ billingForm.controls.state.value }}</p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">Zip/PostalCode:</p>
          <p>{{ billingForm.controls.zip.value }}</p>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="p-1">
          <p class="text-muted">Address:</p>
          <p>{{ billingForm.controls.address.value }}</p>
        </div>
      </div>
      <!-- <div fxLayout="row">
        <div fxFlex="100" class="p-1">
          <h3 class="text-muted mt-2">Delivery information</h3>
          <div class="divider"></div>
          <p class="py-1">
            {{ deliveryForm.controls.deliveryMethod.value["name"] }}
            <span class="text-muted">{{
              deliveryForm.controls.deliveryMethod.value["desc"]
            }}</span>
          </p>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="100" class="p-1">
          <h3 class="text-muted mt-2">Payment information</h3>
          <div class="divider"></div>
          <p class="py-1">
            <span class="text-muted mr-1">Card number:</span
            ><span>XXX-XXX-XXX-7224</span>
          </p>
        </div>
      </div> -->
      <div fxLayout="row" fxLayoutAlign="center center" class="p-1">
        <ng-container *ngIf="paymentMethodId == 0">
          <div class="row justify-content-center">
            <h2 class="text-center">
              <span class="text-muted">Subtotal :</span
              ><span>RM {{ grandTotal | number : "1.2-2" }}</span>
            </h2>
            <h2 class="text-center">
              <span class="text-muted">Delivery Charge :</span
              ><span>RM {{ courierCharge | number : "1.2-2" }}</span>
            </h2>
            <h2 class="text-center">
              <span class="text-muted">Total Price :</span
              ><span
                >RM {{ grandTotal + courierCharge | number : "1.2-2" }}</span
              >
            </h2>
          </div>
        </ng-container>
        <ng-container *ngIf="paymentMethodId == 1">
          <h2>
            <span class="text-muted mr-1">Total Price:</span
            ><span>RM {{ grandTotal | number : "1.2-2" }}</span>
          </h2>
        </ng-container>
        <ng-container *ngIf="paymentMethodId == 2">
          <div class="row justify-content-center">
            <h2 class="text-center">
              <span class="text-muted">Total Price :</span
              ><span>RM {{ grandTotal | number : "1.2-2" }}</span>
            </h2>
            <form [formGroup]="quotationForm">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Remarks / Terms & Conditions</mat-label>
                <textarea
                  matInput
                  type="text"
                  name="addtionalRemark"
                  id="addtionalRemark"
                  formControlName="addtionalRemark"
                  style="min-height: 100px"
                ></textarea>
              </mat-form-field>
            </form>
          </div>
        </ng-container>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="mt-2 p-1">
        <button
          mat-mini-fab
          (click)="backStepper()"
          color="primary"
          matTooltip="Back"
          matTooltipPosition="before"
          class="mr-1"
        >
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="placeOrder()"
          [disabled]="!(this.appService.Data.cartList.length > 0)"
        >
          {{ selectedPaymentOption }}
        </button>
      </div>
    </mat-step>
    <!-- <mat-step label="Confirmation">
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="p-2 text-center"
      >
        <button mat-fab color="primary"><mat-icon>check</mat-icon></button>
        <h2 class="py-2">Congratulation! Your order has been processed</h2>
        <p class="text-muted">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
          dolor ante, ut luctus mi faucibus a. Ut eu tincidunt neque. Proin
          porttitor id ligula id placerat. Integer nec nulla varius, dapibus
          libero quis, semper eros. Aliquam erat volutpat. Proin volutpat tellus
          vel purus interdum euismod.
        </p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="mt-2 p-1">
        <a (click)="closeFunction()" mat-raised-button color="primary"cd
          >Return to Shop</a
        >
      </div>
    </mat-step> -->
  </mat-horizontal-stepper>

  <mat-dialog-content class="mat-elevation-z2" fxHide="false" fxHide.gt-sm>
    <form [formGroup]="billingForm">
      <div fxLayout="row wrap">
        <div class="mb-2" fxFlex="100">
          <label>
            <b>
              <u> Shipping Address</u>
            </b>
          </label>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Saved Addresses</mat-label>
            <mat-select (selectionChange)="updateForm($event.value)">
              <mat-option
                *ngFor="let userAddress of userAddresses"
                [value]="userAddress.id"
              >
                <b>{{ userAddress.address }}</b
                >, {{ userAddress.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="33.3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Name"
              formControlName="name"
              required
            />
            <mat-error *ngIf="billingForm.controls.name.errors?.required"
              >Name is required</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="33.3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              formControlName="email"
              required
            />
            <mat-error>Invalid Email</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="33.3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Phone</mat-label>
            <input
              matInput
              placeholder="Phone"
              formControlName="phone"
              required
            />
            <mat-error *ngIf="billingForm.controls.phone.errors?.required"
              >Phone is required</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="25">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Country</mat-label>
            <mat-select
              placeholder="Country"
              formControlName="country"
              required
            >
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="billingForm.controls.country.errors?.required"
              >Country is required</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="25">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>City</mat-label>
            <input
              matInput
              placeholder="City"
              formControlName="city"
              required
            />
            <mat-error *ngIf="billingForm.controls.city.errors?.required"
              >City is required</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="25">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>State/Province</mat-label>
            <input
              matInput
              placeholder="State/Province"
              formControlName="state"
            />
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="25">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Zip/Postal Code</mat-label>
            <input
              matInput
              placeholder="Zip/Postal Code"
              formControlName="zip"
              required
            />
            <mat-error *ngIf="billingForm.controls.zip.errors?.required"
              >Zip/Postal Code is required</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Address</mat-label>
            <input
              matInput
              placeholder="Address (street, apartment, suite, unit etc.)"
              formControlName="address"
              required
            />
            <mat-error *ngIf="billingForm.controls.address.errors?.required"
              >Address is required</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Select Payment Option</mat-label>
            <mat-select (selectionChange)="pickPaymentMethod($event.value)">
              <mat-option *ngFor="let data of paymentMethod" [value]="data">
                {{ data }}
              </mat-option>
            </mat-select>
            <mat-error>* required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="100">
          <button
            class="w-100 mb-2"
            mat-raised-button
            color="primary"
            [disabled]="!billingForm.valid"
            (click)="placeOrder()"
          >
            {{ selectedPaymentOption }}
          </button>
        </div>

        <div fxFlex="100">
          <button
            class="w-100 mb-4"
            mat-raised-button
            color="secondary"
            (click)="closeFunction()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</mat-dialog-content>
