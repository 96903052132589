import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  emailValidator,
  matchingPasswords,
} from "../../theme/utils/app-validators";
import {
  AuthenticateModel,
  BillServiceProxy,
  CreateUserDto,
  ProductServiceProxy,
  SessionServiceProxy,
  TokenAuthServiceProxy,
  UserServiceProxy,
} from "src/shared/service-proxies/service-proxies";
import { CookieService } from "ngx-cookie-service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup;
  registerForm: FormGroup;

  runChecker = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    private _tokenService: TokenAuthServiceProxy,
    private cookieService: CookieService,
    public dialogRef: MatDialogRef<SignInComponent>,
    public _dialog: MatDialog,
    private _userService: UserServiceProxy
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required])],
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });

    this.registerForm = this.formBuilder.group(
      {
        name: [
          "",
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ],
        lastName: [
          "",
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ],
        email: ["", Validators.compose([Validators.required])],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      { validator: matchingPasswords("password", "confirmPassword") }
    );
  }

  public onLoginFormSubmit(values: Object): void {
    if (this.loginForm.valid) {
      var newModel = new AuthenticateModel();
      newModel.userNameOrEmailAddress = values["email"];
      newModel.password = values["password"];
      newModel.rememberClient = true;

      this._tokenService
        .newAuthenticate(values["email"], values["password"], "Default")
        .subscribe((result) => {
          if (result.accessToken) {
            this.loginSuccess(result);
          } else {
            this.dialogRef.close();
            this.snackBar.open("Authentication Failed!", "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 6000,
            });
          }
        });
    }
  }

  loginSuccess(result) {
    this.cookieService.set("accessToken", result.accessToken);
    // this.router.navigate(["/"]);
    window.location.reload();

    if (this.runChecker == true) {
      this.snackBar.open("You registered successfully!", "×", {
        panelClass: "success",
        verticalPosition: "top",
        duration: 3000,
      });
    }
  }

  public onRegisterFormSubmit(values: Object): void {
    if (this.runChecker == false && this.registerForm.valid) {
      this._tokenService
        .newAuthenticate("admin", "123qwe", "Default")
        .subscribe((result) => {

          if (result.accessToken) {
            this.cookieService.set("accessToken", result.accessToken);

            var newUserObject = new CreateUserDto();

            newUserObject.name = this.registerForm.controls.name.value;
            newUserObject.surname = this.registerForm.controls.lastName.value;
            newUserObject.emailAddress = this.registerForm.controls.email.value;
            newUserObject.userName = this.registerForm.controls.email.value;
            newUserObject.password = this.registerForm.controls.password.value;
            newUserObject.isActive = true;

            if (this.runChecker == false) {
              this._userService
                .createStandardUsers(newUserObject)
                .subscribe((result) => {


                  if (result.name != null) {
                    var newModel = new AuthenticateModel();
                    newModel.userNameOrEmailAddress =
                      newUserObject.emailAddress;
                    newModel.password = newUserObject.password;
                    newModel.rememberClient = true;

                    this.cookieService.deleteAll();

                    this._tokenService
                      .newAuthenticate(
                        values["email"],
                        values["password"],
                        "Default"
                      )
                      .subscribe((result) => {
                        if (result.accessToken) {
                          this.loginSuccess(result);
                        } else {
                          console.log(newModel);
                          this._tokenService
                            .authenticate(newModel)
                            .subscribe((result2) => {
                              if (result2 != null && result2.accessToken) {
                                this.loginSuccess(result2);
                              }
                            });
                        }
                      });
                  } else {
                    this.cookieService.deleteAll();
                    this.runChecker = false;


                    this.snackBar.open("User Already Exist!", "×", {
                      panelClass: "danger",
                      verticalPosition: "top",
                      duration: 3000,
                    });
                  }
                });
            }

            this.runChecker = true;
          }
        });
    }
  }

  forgotPasswordPopup() {
    this.dialogRef.close();
    const dialogRef = this._dialog.open(ResetPasswordComponent, {
      width: "100%",
      data: {
        merchantUrl: location.href
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });
  }



}
