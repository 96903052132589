<div *ngIf="products" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">      
        <div *ngFor="let product of products" class="swiper-slide">
            <mat-card class="product-item">
                <mat-chip-list *ngIf="product.discount">
                    <mat-chip color="warn" selected="true">{{product.discount}}% OFF</mat-chip>
                </mat-chip-list>
                <a
                (click)="productPopupBig(product)"
                class="d-none d-md-block image-link"
                style="max-height: 200px; min-height: 100px;"
              >
                <div
                  *ngIf="product.images.length > 0"
                  style="height: 200px; width: auto"
                >
                  <img
                    class="fitWidthImg"
                    [src]="product.images[0]?.medium"
                    alt=""
                    style="height: 200px; width: auto"
                  />
                </div>
                <div
                  *ngIf="product.images.length == 0"
                  style="height: 200px; width: auto"
                >
                  <img
                    class="mt-3"
                    src="assets/images/tfx/emptyImg.png"
                    alt=""
                    style="height: 200px; width: auto; opacity: 0.2"
                  />
                </div>
                <div class="title text-truncate mt-1">
                  {{ product.name }}
                </div>
              </a>
              <a
                (click)="productPopupSmall(product)"
                class="d-md-none image-link"
                style="max-height: 200px; min-height: 100px;"
              >
                <div
                  *ngIf="product.images.length > 0"
                  style="height: 100px; width: auto"
                >
                  <img
                    class="fitWidthImg"
                    [src]="product.images[0]?.medium"
                    alt=""
                    style="height: 100px; width: auto"
                  />
                </div>
                <div
                  *ngIf="product.images.length == 0"
                  style="height: 100px; width: auto"
                >
                  <img
                    class="mt-3"
                    src="assets/images/tfx/emptyImg.png"
                    alt=""
                    style="height: 200px; width: auto; opacity: 0.2"
                  />
                </div>
              </a>
              <div class="title text-truncate mt-1">
                  {{ product.name }}
                </div>
                <!-- <h4 class="category text-muted">{{ ( appService.Data.categories | filterById : product.categoryId )?.name }}</h4>                                                                     
                <a [routerLink]="['/products', product.id, product.name]" class="title text-truncate">
                    {{product.name}}
                </a> -->
                <div fxLayout="row" fxLayoutAlign="space-between center" class="prices">
                    <div fxLayout="column" fxLayoutAlign="center start">
                        <p class="old-price text-muted"><span *ngIf="product.oldPrice && product.oldPrice !== product.price">RM{{product.oldPrice | number : '1.2-2'}}</span></p>
                        <p class="new-price">RM{{product.price | number : '1.2-2'}}</p>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center end">
                      <p class="new-price">
                        {{
                          product.availibilityCount > 0 || product.isTypeSerice
                            ? ""
                            : "No Stock"
                        }}
                      </p>
                    </div>
                    <!-- <app-rating [ratingsCount]="product.ratingsCount" [ratingsValue]="product.ratingsValue" [direction]="'column'"></app-rating> -->
                </div>                            
                <div class="divider mt-2"></div>
                <div class="icons">
                    <app-controls [product]="product" (onOpenProductDialog)="openProductDialog(product)"></app-controls>
                </div>
            </mat-card>
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
</div>