import { UserAddressesServiceProxy, UserAddressDto, UserAddress, EasyParcelServiceProxy, CheckrateReponseDto, Rate, CompanyProfileServiceProxy, CompanyProfile } from './../../../shared/service-proxies/service-proxies';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import * as moment from "moment";
import { CookieService } from "ngx-cookie-service";
import {
  BillDto,
  BillItemDto,
  BillItemServiceProxy,
  BillServiceProxy,
  CreateBillDto,
  CreateBillItemDto,
  CreateCustomerDto,
  Customer,
  CustomerDto,
  CustomerServiceProxy,
  OrderItemsDto,
  OrdersDto,
  OrdersServiceProxy,
  PaymentServiceProxy,
  SessionServiceProxy,
  UserServiceProxy,
} from "src/shared/service-proxies/service-proxies";
import { Data, AppService } from "../../app.service";
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: "app-checkout-popup",
  templateUrl: "./checkout-popup.component.html",
  styleUrls: ["./checkout-popup.component.scss"],
})
export class CheckoutPopupComponent implements OnInit {
  @Output() selectionChange: EventEmitter<MatSelectChange>
  @ViewChild("horizontalStepper", { static: true })
  horizontalStepper: MatStepper;
  // @ViewChild("verticalStepper", { static: true }) verticalStepper: MatStepper;
  billingForm: FormGroup;
  deliveryForm: FormGroup;
  paymentForm: FormGroup;
  quotationForm: FormGroup;
  countries = [];
  months = [];
  years = [];
  deliveryMethods = [];
  courierName = "";
  courierId = "";
  courierCharge = 0.0;
  grandTotal = 0;

  paymentMethod = ["Pay Now", "Place Order", "Ask for Quotation"];

  paymentMethodId = 1;
  selectedPaymentOption = "Place Order";

  userId: number = 0;

  userAddresses: Array<UserAddress> = []
  isMatched: number = 0
  rateList: Rate[] = [];

  myCompanyObj: CompanyProfile

  constructor(
    public appService: AppService,
    public formBuilder: FormBuilder,
    private _orderService: OrdersServiceProxy,
    public dialogRef: MatDialogRef<CheckoutPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    private _sessionService: SessionServiceProxy,
    private _paymentService: PaymentServiceProxy,
    private _billingService: BillServiceProxy,
    private _customerService: CustomerServiceProxy,
    private _billItemService: BillItemServiceProxy,
    public snackBar: MatSnackBar,
    private _easyparcelService: EasyParcelServiceProxy,
    private _userAddressesService: UserAddressesServiceProxy,
    private _companyProfile: CompanyProfileServiceProxy,
  ) { }

  ngOnInit() {
    this._sessionService.getCurrentLoginInformations().subscribe(result => {
      this.userId = result.user.id;
    })
    this._companyProfile.getCompanyprofileByRegNo(this.myData.companyRegNo).subscribe(result => {
      this.myCompanyObj = result
    })
    this.isMatched = 0
    console.log("userId", this.userId)
    this.getUserAddresses(this.userId)

    this.appService.Data.cartList.forEach((product) => {
      this.grandTotal += product.quantity * product.unitPrice;
    });
    this.countries = this.appService.getCountries();
    var malaysiaObj = this.countries.find((x) => x.name == "Malaysia");

    this.months = this.appService.getMonths();
    this.years = this.appService.getYears();
    this.deliveryMethods = this.appService.getDeliveryMethods();
    this.billingForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", Validators.required],
      country: [malaysiaObj, Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      zip: ["", Validators.required],
      address: ["", Validators.required],
    });
    this.deliveryForm = this.formBuilder.group({
      deliveryMethod: [this.deliveryMethods[0], Validators.required],
    });
    this.paymentForm = this.formBuilder.group({
      selectedPaymentOption: ["Place Order", Validators.required],
    });
    this.quotationForm = this.formBuilder.group({
      addtionalRemark: [""],
    });


    this._easyparcelService.checkRate(
      60000,
      15000,
      1
    ).subscribe(
      (rateResults: CheckrateReponseDto) => {
        if (rateResults.result.length > 0) {
          rateResults.result[0].rates.forEach(
            (eachRate: Rate) => {
              //take DHL
              if (eachRate.courier_id == 'EP-CR0C') {
                console.log(eachRate)
                console.log(eachRate.courier_name + " --> RM " + eachRate.price)
                // this.courierCharge = eachRate.price;
                this.courierName = eachRate.courier_name;
                this.courierId = 'EP-CR0C';
              }
            }
          );
          //if DHL doesnt exist
          if (this.courierName == "") {
            this.courierName = rateResults.result[0].rates[0].courier_name;
            this.courierCharge = rateResults.result[0].rates[0].price;
            this.courierId = rateResults.result[0].rates[0].courier_id;
          }
        }

      }
    )

  }

  getUserAddresses(userId: number) {
    this._userAddressesService.getUserAddress(userId).subscribe(result => {
      if (result.length > 0) {
        this.userAddresses = result
      }
    })
  }

  updateForm(event: any) {
    this.userAddresses.forEach(e => {
      if (e.id === event) {
        this.billingForm.controls.name.setValue(e.name);
        this.billingForm.controls.phone.setValue(e.phone);
        this.billingForm.controls.email.setValue(e.email);
        this.billingForm.controls.address.setValue(e.address);
        this.billingForm.controls.zip.setValue(e.postcode);
        // this.billingForm.controls.country.setValue(this.userAddresses[index]?.country);
        this.billingForm.controls.city.setValue(e.city);
        this.billingForm.controls.state.setValue(e.state);
      }
    })
  }

  public placeOrder() {
    if (this.appService.Data.cartList.length > 0) {
      var newUserAddress = new UserAddressDto()
      newUserAddress.name = this.billingForm.controls.name.value;
      newUserAddress.phone = this.billingForm.controls.phone.value;
      newUserAddress.email = this.billingForm.controls.email.value;
      newUserAddress.address = this.billingForm.controls.address.value;
      newUserAddress.postcode = this.billingForm.controls.zip.value;
      newUserAddress.userId = this.userId;
      newUserAddress.country = this.billingForm.controls.country.value.name;
      newUserAddress.city = this.billingForm.controls.city.value;

      this.userAddresses.forEach(e => {
        if (e.address === newUserAddress.address && e.name === newUserAddress.name) this.isMatched++
      })

      if (this.isMatched <= 0 || this.userAddresses.length <= 0) {
        this._userAddressesService.createUserAddress(newUserAddress).subscribe(result => { })
      }

      this._customerService.getCustomerByEmail(
        this.myData.tenantId,
        this.billingForm.controls.email.value,
        this.billingForm.controls.name.value
      ).subscribe(
        customerObject => {
          if (customerObject.email == null) {
            //if new customer
            var createNewCustomer = new CustomerDto();
            createNewCustomer.name = this.billingForm.controls.name.value;
            createNewCustomer.contactNo = this.billingForm.controls.phone.value;
            createNewCustomer.email = this.billingForm.controls.email.value;
            createNewCustomer.billingAddress1 = this.billingForm.controls.address.value;
            createNewCustomer.postCode = this.billingForm.controls.zip.value;
            createNewCustomer.tenantId = this.myData.tenantId;
            createNewCustomer.country = this.billingForm.controls.country.value.name;
            createNewCustomer.city = this.billingForm.controls.city.value;

            this._customerService.marketplaceCreateCustomer(createNewCustomer).subscribe(
              result => {
                this.createInvoiceMethod(result);

              }
            );
          } else {
            //if customer exist (repeating customer)
            this.createInvoiceMethod(customerObject);

          }
        }
      );

    }
  }



  createInvoiceMethod(customerObject: any) {
    // if (this.paymentForm.controls.selectedPaymentOption.value == "Pay Now") {
    if (this.paymentMethodId == 0) {

      this._billingService.generateNewIvoiceNumber(this.myData.tenantId).subscribe(
        invoiceNumber => {
          var dateToday = moment();

          var tempBill = new BillDto();
          tempBill.billNo = invoiceNumber;
          tempBill.billType = 'Invoice';
          tempBill.tenantId = this.myData.tenantId;
          tempBill.totalAmount = this.appService.Data.totalPrice + this.courierCharge;
          tempBill.status = "Sent";
          tempBill.dueDate = dateToday;
          tempBill.issueDate = dateToday;
          tempBill.customerId = customerObject.id;


          tempBill.shippingName = this.billingForm.controls.name.value;
          tempBill.shippingAddress1 = this.billingForm.controls.address.value;
          tempBill.shippingPoscode = this.billingForm.controls.zip.value;
          tempBill.shippingCountry = this.billingForm.controls.country.value.name;
          tempBill.shippingCity = this.billingForm.controls.city.value;


          this._billingService.marketplaceCreateNewInvoice(tempBill).subscribe(
            billobject => {

              this.createOrder(customerObject, billobject.id);

              billobject.billId = billobject.id;
              //create billitem
              this.appService.Data.cartList.forEach((product) => {
                if (product) {
                  var billItemObject = new BillItemDto();
                  billItemObject.billId = billobject.id;
                  billItemObject.discount = product.discount;
                  billItemObject.productId = product.productId;
                  billItemObject.tax = product.tax;
                  billItemObject.taxCode = product.taxCode;
                  billItemObject.unitPrice = product.unitPrice;
                  billItemObject.quantity = 1;

                  this._billItemService.marketplaceCreateBillItem(billItemObject).subscribe(result2 => {
                  })
                }
              });

              // tempBill = billobject;
              //create payment url
              tempBill.id = billobject.id;
              tempBill.billId = billobject.id;
              tempBill.customer = customerObject;
              tempBill.redirectUrl = "https://tfxebilling.com/store/" + this.myData.companyRegNo;

              this._paymentService.createBillPaymentURL(tempBill).subscribe((paymentResult) => {

                if (paymentResult.url != null) {
                  this.horizontalStepper._steps.forEach(
                    (step) => (step.editable = false)
                  );
                  // this.verticalStepper._steps.forEach(
                  //   (step) => (step.editable = false)
                  // );
                  this.appService.Data.cartList.length = 0;
                  this.appService.Data.totalPrice = 0;
                  this.appService.Data.totalCartCount = 0;
                  window.location.href = paymentResult.url;
                }
              });
            }
          )

        }
      )

    }
    if (this.paymentMethodId == 1) {
      this.createOrder(customerObject, null);
      this.dialogRef.close();
      this.snackBar.open('You have successfully placed an order to the merchant', 'x', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    }
    if (this.paymentMethodId == 2) {
      this.createOrder(customerObject, null);
      this.dialogRef.close();
      this.snackBar.open('You have successfully asked for a quotation from the merchant', 'x', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    }

  }

  createOrder(customerObject: CustomerDto, billId) {
    const newOrder = new OrdersDto();

    //billing info
    newOrder.name = this.billingForm.controls.name.value;
    newOrder.email = this.billingForm.controls.email.value;
    newOrder.phone = this.billingForm.controls.phone.value;
    newOrder.country = this.billingForm.controls.country.value.name;
    newOrder.city = this.billingForm.controls.city.value;
    newOrder.state = this.billingForm.controls.state.value;
    newOrder.postcode = this.billingForm.controls.zip.value;
    newOrder.address = this.billingForm.controls.address.value;
    newOrder.userId = this.userId;
    newOrder.tenantId = this.myData.tenantId;
    newOrder.customerId = customerObject.id;
    newOrder.billId = billId;
    //payment
    newOrder.paymnetMethodId = 0;
    newOrder.orderStatusId = 0;
    newOrder.courierCharges = this.courierCharge;
    newOrder.courierName = this.courierName;
    newOrder.courierId = this.courierId;
    //quotation
    newOrder.addtionalRemark = this.quotationForm.controls.addtionalRemark.value;

    const newCustomer = new CustomerDto();
    newCustomer.name = this.billingForm.controls.name.value;
    newCustomer.email = this.billingForm.controls.email.value;
    newCustomer.contactNo = this.billingForm.controls.phone.value;
    newCustomer.billingAddress1 = this.billingForm.controls.address.value;
    newCustomer.postCode = this.billingForm.controls.zip.value;
    newCustomer.city = this.billingForm.controls.city.value;
    newCustomer.state = this.billingForm.controls.state.value;
    newCustomer.country = this.billingForm.controls.country.value.name;
    newCustomer.userId = this.userId;
    newCustomer.tenantId = this.myData.tenantId;

    this._customerService.checkCustomer(newCustomer).subscribe(result => {
      newOrder.customerId = result.id;
      this._orderService.createOrder(newOrder).subscribe((result) => {
        var orderIdReference = result.orderIdReference
        var orderId = result.orderId;
        let totalAmount = 0;
  
  
        this.appService.Data.cartList.forEach((eachItem) => {
          if (eachItem) {
            var newObject = new OrderItemsDto();
            newObject.orderIdReference = orderIdReference
            newObject.orderId = orderId;
            newObject.productId = eachItem.id;
            newObject.quantity = eachItem.quantity;
            newObject.userId = this.userId;
            newObject.merchantTenantId = eachItem.tenantId;
            newObject.price = eachItem.unitPrice;
  
            totalAmount += (eachItem.quantity * eachItem.unitPrice);
  
            this._orderService
              .createOrderItems(newObject)
              .subscribe((result) => {
              });
          }
        });
  
        newOrder.totalAmount = totalAmount;
        newOrder.orderId = orderId;
        newOrder.tenantId = this.myData.tenantId;
  
        this._orderService.updateOrders(orderId, newOrder).subscribe(
          result => {
            this._orderService.sendOrderNotification(newOrder).subscribe(
              _ => {
  
              }
            );
          }
        )
  
        this.clear();
  
      });
    })

  }

  pickPaymentMethod(data) {
    this.paymentMethodId = this.paymentMethod.indexOf(data);
  }

  pickPaymentBox(id) {
    this.paymentMethodId = id;
    this.paymentForm.get("selectedPaymentOption").setValue(this.paymentMethod[id]);
    this.selectedPaymentOption = this.paymentMethod[id]
  }

  closeFunction() {
    this.dialogRef.close();
  }

  public clear() {
    this.appService.Data.cartList.forEach((product) => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }

  nextStepper(step) {
    if (step == 1 && this.billingForm.valid) {
      this.horizontalStepper.next();
    }

    if (step == 2) {
      this.horizontalStepper.next();
    }

  }

  backStepper() {
    this.horizontalStepper.previous();
  }
}

export interface DialogData {
  tenantId: number;
  companyRegNo: string;
}
