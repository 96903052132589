<mat-dialog-content>
  <div fxLayout="row">
    <div class="col">
      <div class="card w-100">
        <mat-tab-group mat-stretch-tabs>
          <mat-tab label="Login">
            <div class="p-2 w-100">
              <form [formGroup]="loginForm">
                <mat-form-field appearance="outline" class="w-100 mt-2">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    required
                  />
                  <mat-error *ngIf="loginForm.controls.email.errors?.required"
                    >Email is required</mat-error
                  >
                  <mat-error
                    *ngIf="loginForm.controls.email.hasError('invalidEmail')"
                    >Invalid email address</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 mt-1">
                  <mat-label>Password</mat-label>
                  <input
                    matInput
                    placeholder="Password"
                    formControlName="password"
                    type="password"
                    required
                  />
                  <mat-error
                    *ngIf="loginForm.controls.password.errors?.required"
                    >Password is required</mat-error
                  >
                  <mat-error
                    *ngIf="loginForm.controls.password.hasError('minlength')"
                    >Password isn't long enough, minimum of 6
                    characters</mat-error
                  >
                </mat-form-field>
                <div class="text-center mt-2">
                  <button
                    mat-fab
                    color="primary"
                    class="mat-elevation-z6"
                    (click)="onLoginFormSubmit(loginForm.value)"
                  >
                    <mat-icon>exit_to_app</mat-icon>
                  </button>
                </div>
                <div class="text-center" style="margin-top: 20px">
                  <a mat-button (click)="forgotPasswordPopup()">
                    <u> Forgot Password </u>
                  </a>
                </div>
        
              </form>
            </div>
          </mat-tab>
          <mat-tab label="Sign Up">
            <div class="p-2 w-100">
              <form [formGroup]="registerForm">
                <mat-form-field appearance="outline" class="w-100 mt-1">
                  <mat-label>Fist Name</mat-label>
                  <input
                    matInput
                    placeholder="First Name"
                    formControlName="name"
                    required
                  />
                  <mat-error *ngIf="registerForm.controls.name.errors?.required"
                    >First Name is required</mat-error
                  >
                  <mat-error
                    *ngIf="registerForm.controls.name.hasError('minlength')"
                    >First Name isn't long enough, minimum of 3
                    characters</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 mt-1">
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    placeholder="Last Name"
                    formControlName="lastName"
                    required
                  />
                  <mat-error
                    *ngIf="registerForm.controls.lastName.errors?.required"
                    >Last Name is required</mat-error
                  >
                  <mat-error
                    *ngIf="registerForm.controls.name.hasError('minlength')"
                    >Last Name isn't long enough, minimum of 3
                    characters</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 mt-1">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    required
                  />
                  <mat-error
                    *ngIf="registerForm.controls.email.errors?.required"
                    >Email is required</mat-error
                  >
                  <mat-error
                    *ngIf="registerForm.controls.email.hasError('invalidEmail')"
                    >Invalid email address</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 mt-1">
                  <mat-label>Password</mat-label>
                  <input
                    matInput
                    placeholder="Password"
                    formControlName="password"
                    type="password"
                    minlength="6"
                    required
                  />
                  <mat-error
                    *ngIf="registerForm.controls.password.errors?.required"
                    >Password is required</mat-error
                  >
                  <mat-error
                    *ngIf="registerForm.controls.password.hasError('minlength')"
                    >Password isn't long enough, minimum of 6
                    characters</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100 mt-1">
                  <mat-label>Confirm Password</mat-label>
                  <input
                    matInput
                    placeholder="Confirm Password"
                    formControlName="confirmPassword"
                    type="password"
                    required
                  />
                  <mat-error
                    *ngIf="
                      registerForm.controls.confirmPassword.errors?.required
                    "
                    >Confirm Password is required</mat-error
                  >
                  <mat-error
                    *ngIf="
                      registerForm.controls.confirmPassword.hasError(
                        'mismatchedPasswords'
                      )
                    "
                    >Passwords do not match</mat-error
                  >
                </mat-form-field>
                <div class="text-center mt-2">
                  <button
                    mat-fab
                    color="primary"
                    class="mat-elevation-z6"
                    (click)="onRegisterFormSubmit(registerForm.value)"
                  >
                    <mat-icon>person_add</mat-icon>
                  </button>
                </div>
              </form>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</mat-dialog-content>
