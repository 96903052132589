<div class="main-slider">
  <div class="swiper-container h-100" [swiper]="config">
    <div class="swiper-wrapper h-100">
      <div *ngFor="let slide of slides" class="swiper-slide">
        <div
          [attr.data-background]="slide.image"
          class="slide-item swiper-lazy"
        >
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          <div class="mask"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="content">
            <h1>{{ slide.title }}</h1>
            <h3>{{ slide.subtitle }}</h3>
            <button
              *ngIf="showButton == true"
              (click)="navigate(slide)"
              mat-raised-button
              color="primary"
            >
              {{ slide.button }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showButton == true">
      <div class="swiper-pagination white"></div>
      <button
        mat-mini-fab
        color="primary"
        class="swiper-button-prev swipe-arrow"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="primary"
        class="swiper-button-next swipe-arrow"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </ng-container>
  </div>
</div>
