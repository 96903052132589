import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
// import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from "./service-proxies";
import { AppInterceptor } from "src/app/theme/utils/app-interceptor";

@NgModule({
  providers: [
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.ConfigurationServiceProxy,
    ApiServiceProxies.ProductServiceProxy,
    ApiServiceProxies.CustomerServiceProxy,
    ApiServiceProxies.BillServiceProxy,
    ApiServiceProxies.BillItemServiceProxy,
    ApiServiceProxies.CompanyProfileServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.OrdersServiceProxy,
    ApiServiceProxies.UserAddressesServiceProxy, 
    ApiServiceProxies.EasyParcelServiceProxy,
    ApiServiceProxies.CartServiceProxy,
    ApiServiceProxies.WishlistServiceProxy,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
})
export class ServiceProxyModule { }
