<mat-card
  *ngIf="appService.Data.cartList.length == 0"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="text-center py-5"
>
  <svg viewBox="0 0 24 24" class="mat-icon empty-cart-icon primary-color">
    <path
      d="M19 20C19 21.11 18.11 22 17 22C15.89 22 15 21.1 15 20C15 18.89 15.89 18 17 18C18.11 18 19 18.9 19 20M7 18C5.89 18 5 18.89 5 20C5 21.1 5.89 22 7 22C8.11 22 9 21.11 9 20S8.11 18 7 18M7.2 14.63L7.17 14.75C7.17 14.89 7.28 15 7.42 15H19V17H7C5.89 17 5 16.1 5 15C5 14.65 5.09 14.32 5.24 14.04L6.6 11.59L3 4H1V2H4.27L5.21 4H20C20.55 4 21 4.45 21 5C21 5.17 20.95 5.34 20.88 5.5L17.3 11.97C16.96 12.58 16.3 13 15.55 13H8.1L7.2 14.63M8.5 11H10V9H7.56L8.5 11M11 9V11H14V9H11M14 8V6H11V8H14M17.11 9H15V11H16L17.11 9M18.78 6H15V8H17.67L18.78 6M6.14 6L7.08 8H10V6H6.14Z"
    />
  </svg>
  <h1 class="mt-3">Your Cart Is Currently Empty!</h1>
  <p class="text-muted">
    Before proceed to checkout you must add some items to your shopping cart.
  </p>
  <a (click)="closeFunction()" mat-raised-button color="primary" class="mt-3"
    >Return To Shop</a
  >
</mat-card>

<mat-card *ngIf="appService.Data.cartList?.length > 0" class="p-0">
  <div class="mat-table cart-table">
    <div class="d-none d-lg-block">
      <div class="mat-header-row">
        <div class="mat-header-cell">Product</div>
        <div class="mat-header-cell">Name</div>
        <div class="mat-header-cell">Price</div>
        <div class="mat-header-cell">Quantity</div>
        <div class="mat-header-cell">Total</div>
        <div class="mat-header-cell text-center">
          <div class="px-1">
            <button mat-raised-button color="warn" (click)="clear()">
              Clear All
            </button>
          </div>
        </div>
      </div>
      <div *ngFor="let product of appService.Data.cartList" class="mat-row">
        <ng-container *ngIf="product?.productDto?.images?.length > 0">
          <div class="mat-cell"><img [src]="product?.productDto?.images[0]?.small" /></div>
        </ng-container>
        <div class="mat-cell">
          <a class="product-name">{{ product.name }}</a>
        </div>
        <div class="mat-cell">RM{{ product.unitPrice | number : "1.2-2" }}</div>
        <div class="mat-cell text-muted">
          <!-- <app-controls
            [product]="product"
            [type]="'wish'"
            (onQuantityChange)="updateCart($event)"
          ></app-controls> -->
          {{ product.quantity }}
        </div>
        <div class="mat-cell">RM{{ total[product.productId] | number : "1.2-2" }}</div>
        <div class="mat-cell text-center">
          <div class="p-1">
            <button
              mat-mini-fab
              color="warn"
              (click)="remove(product)"
              matTooltip="Clear"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="mat-row">
        <div class="mat-cell"></div>
        <div class="mat-cell"></div>
        <div class="mat-cell"></div>
        <div class="mat-cell text-right">
          <div
            fxLayout="column"
            fxLayoutAlign="center end"
            class="grand-total px-2"
          >
            <!-- <span>SubTotal:</span> -->
            <span class="new-price">Grand Total:</span>
          </div>
        </div>
        <div class="mat-cell">
          <div
            fxLayout="column"
            fxLayoutAlign="center start"
            class="grand-total"
          >
            <!-- <span>RM{{ grandTotal | number: "1.2-2" }}</span> -->
            <span class="new-price">RM{{ grandTotal | number : "1.2-2" }}</span>
          </div>
        </div>
        <div class="mat-cell text-center"></div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="d-lg-none p-2">
        <h4>Cart Summary</h4>
        <app-custom-table
          [inputTableDataSource]="CartTable"
          [tableColumns]="CartTableColumn"
          [showTitle]="true"
          [isLeftTitle]="false"
          [totalItem]="totalItem"
          [defaultPageSize]="5"
          [titleRowArray]="[
            CartTable.data.length > 0 ? CartTable.data.length - 1 : 0
          ]"
          [rowActionIcon]="'trash'"
          (rowAction)="deleteItem($event)"
        >
        </app-custom-table>
      </div>
    </mat-dialog-content>

    <div
      class="row justify-content-end w-100"
      style="padding-left: 30px !important"
    >
      <a
        class="m-1"
        (click)="closeFunction()"
        mat-raised-button
        color="basic"
        >{{ myData.isPos == true ? "Add Order" : "Continue shopping" }}</a
      >
      <a
        class="m-1"
        (click)="checkoutFunction()"
        mat-raised-button
        color="primary"
        *ngIf="myData.isPos == false"
        >Proceed To Checkout</a
      >
      <a
        class="m-1"
        (click)="checkoutFunction()"
        mat-raised-button
        color="primary"
        *ngIf="myData.isPos == true"
        >Confirm Order</a
      >
    </div>
  </div>
</mat-card>
