<mat-dialog-content>
  <div class="row">
    <div class="col search-bar">
      <input
        type="text"
        placeholder="Search product..."
        class="mat-elevation-z3"
        [(ngModel)]="filterText"
        (change)="searchFunction()"
        name="filterText"
      />
    </div>

    <button mat-icon-button type="button" (click)="searchFunction()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</mat-dialog-content>
