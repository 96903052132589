<ng-container>
  <!-- Filter -->
  <ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
    <div class="row align-items-center mx-1 justify-content-center">
      <div class="col-md-6 mt-3 mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              type="button"
              class="btn bg-primary"
              (click)="applyFilter()"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
          <input
            name="filterText"
            autoFocus
            class="form-control m-input"
            type="text"
            [(ngModel)]="filterText"
            (keyup.enter)="applyFilter()"
          />
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Table -->

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="tableDataSource"
      matSort
      (matSortChange)="sortTable($event)"
      class="p-1 table-hover"
    >
      <!-- action column -->
      <ng-container [matColumnDef]="rowActionIcon">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="newTitle"
          style="background: transparent"
        >
          <!-- Action -->
        </th>

        <td
          *matCellDef="let element"
          [id]="rowActionIcon"
          class="actionRow justify-content-center"
          class="newData2"
        >
          <ng-container *ngIf="canEdit == true && element.id != null">
            <div class="row ml-1" style="flex-wrap: nowrap">
              <button
                type="button"
                class="btn btn-outline-primary btn-icon mt-2 actionRow mr-1"
                (click)="emitEditRowAction(element)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-danger btn-icon mt-2 actionRow"
                (click)="emitRowAction(element)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="canEdit == false && element.id != null">
            <ng-container *ngIf="rowActionIcon == 'edit'">
              <button
                type="button"
                class="btn btn-outline-primary btn-icon mt-2 actionRow mr-1"
                (click)="emitRowAction(element)"
              >
                <i class="fa fa-edit"></i>
              </button>
            </ng-container>

            <ng-container *ngIf="rowActionIcon != 'edit'">
              <!-- <button
                type="button"
                class="btn btn-outline-danger btn-icon actionRow"
                (click)="emitRowAction(element)"
              >
                <i class="fa fa-trash"></i>
              </button> -->
              <button mat-icon-button (click)="emitRowAction(element)">
                <mat-icon style="color: red;">delete</mat-icon>
              </button>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let tableColumn of tableColumns"
        [matColumnDef]="tableColumn.name"
      >
        <!-- if sortable column header -->
        <ng-container
          *ngIf="
            tableColumn.isSortable == true || isSortable == true;
            else notSortable
          "
        >
          <th
            mat-sort-header
            class="rowTitle"
            [ngStyle]="{
              width: tableColumn.width ? tableColumn.width : '100vw'
            }"
            header-cell
            *matHeaderCellDef
            [mat-sort-header]="tableColumn.name"
            [arrowPosition]="
              tableColumn.position === 'right' ? 'before' : 'after'
            "
          >
            <div class="justify-content-center">
              {{ tableColumn.name }}
            </div>
          </th>
        </ng-container>
        <!-- else not sortable -->
        <ng-template #notSortable>
          <ng-container
            mat-header-cell
            *matHeaderCellDef
            [class.text-right]="tableColumn.position == 'right'"
          >
            <th
              class="rowTitle"
              [ngStyle]="{
                width: tableColumn.width ? tableColumn.width : '100vw'
              }"
            >
              {{ tableColumn.name }}
            </th>
          </ng-container>
        </ng-template>

        <!-- column data -->

        <ng-container *matCellDef="let element">
          <ng-container *ngIf="isLeftTitle == true; else notLeftTitle">
            <ng-container *ngIf="isLeftTitleArray == false">
              <ng-container *ngIf="tableColumns.indexOf(tableColumn) == 0">
                <ng-container
                  *ngIf="
                    titleRowArray.includes(
                      tableDataSource.filteredData.indexOf(element)
                    ) == false;
                    else darkTitle
                  "
                >
                  <td
                    class="newTitle"
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw'
                    }"
                  >
                    {{ element[tableColumn.dataKey] }}
                  </td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="tableColumns.indexOf(tableColumn) != 0">
                <ng-container
                  *ngIf="isInputTable == false && tableColumn.type == null"
                >
                  <td
                    class="newData"
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw'
                    }"
                  >
                    {{
                      isNumber(
                        element[tableColumn.dataKey],
                        tableColumn.name.toLowerCase()
                      )
                        ? (element[tableColumn.dataKey] | number: "1.2-2")
                        : element[tableColumn.dataKey]
                    }}
                  </td>
                </ng-container>
                <ng-container
                  *ngIf="isInputTable == true && tableColumn.type == null"
                >
                  <td class="newData m-0 p-0">
                    <ng-container>
                      <textarea
                        class="form-control newDataInput"
                        type="text"
                        style="height: 50px; border-color: white; resize: none"
                        value="{{
                          tableDataSource.data[element.col1 - 1][
                            'col' + (tableColumns.indexOf(tableColumn) + 2)
                          ]
                        }}"
                        (change)="
                          onInputChange(
                            element,
                            tableColumns.indexOf(tableColumn),
                            $event
                          )
                        "
                      ></textarea>
                    </ng-container>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                isLeftTitleArray == true &&
                tableColumns.indexOf(tableColumn) == 0
              "
            >
              <div>
                <ng-container *ngFor="let eachColumn of tableColumns">
                  <td class="newContainer">
                    <label
                      class="newData3"
                      *ngIf="
                        tableColumns.indexOf(eachColumn) == 0 &&
                        tableDataSource.filteredData.length > 1
                      "
                    >
                      <ng-container
                        *ngIf="
                          tableDataSource.filteredData.indexOf(element) == 0
                        "
                      >
                        <div>
                          {{ element.title }} No:
                          {{
                            tableDataSource.filteredData.indexOf(element) + 1
                          }}
                        </div>
                      </ng-container>

                      <ng-container
                        *ngIf="
                          tableDataSource.filteredData.indexOf(element) != 0
                        "
                      >
                        <div>
                          {{ element.title }} No:
                          {{
                            tableDataSource.filteredData.indexOf(element) + 1
                          }}
                        </div>
                      </ng-container>
                    </label>
                    <div class="row m-0 p-0">
                      <div class="col-md-6 m-0 newTitle2">
                        {{ eachColumn.name }}
                      </div>
                      <div class="col-md-6 m-0 newData2">
                        {{ element[eachColumn.dataKey] }}
                      </div>
                    </div>
                  </td>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <ng-template #notLeftTitle>
            <ng-container
              *ngIf="
                titleRowArray.includes(
                  tableDataSource.filteredData.indexOf(element)
                ) == false;
                else darkTitle
              "
            >
              <ng-container *ngIf="tableColumn.type != null">
                <ng-container *ngIf="tableColumn.type == 'type'">
                  <td
                    class="newData"
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw'
                    }"
                  >
                    <span
                      class="badge w-100"
                      [ngClass]="{
                        'badge-secondary': element.status == 'Pending',
                        'badge-info': element.status == 'Sent',
                        'badge-danger': element.status == 'Overdue',
                        'badge-success': element.status == 'Paid'
                      }"
                      >{{ element.status }}</span
                    >
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'underlineButton'">
                  <td
                    class="newData"
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw'
                    }"
                  >
                    <button
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">
                        {{
                          element.title
                            ? element.title
                            : element[tableColumn.dataKey]
                        }}
                      </u>
                    </button>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'primaryButton'">
                  <td
                    class="newData"
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw'
                    }"
                  >
                    <button
                      class="btn btn-primary m-0 text-light"
                      (click)="
                        sendMessage($event, element, tableColumn.typeName)
                      "
                    >
                      {{ element.title }}
                    </button>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'invoiceAction'">
                  <td class="newData">
                    <ng-container *ngIf="element.status == 'Pending'">
                      <div class="row justify-content-center">
                        <a
                          class="text-secondary pt-2"
                          (click)="sendMessage($event, element, 'sendbill')"
                          >Send</a
                        >
                        <button mat-icon-button [matMenuTriggerFor]="actions">
                          <mat-icon class="material-icons-outlined grey-icon"
                            >expand_circle_down</mat-icon
                          >
                        </button>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        element.status == 'Sent' || element.status == 'Overdue'
                      "
                    >
                      <div class="row justify-content-center">
                        <a
                          class="pt-2"
                          (click)="sendMessage($event, element, 'paybill')"
                          >Paid</a
                        >
                        <button mat-icon-button [matMenuTriggerFor]="actions">
                          <mat-icon
                            class="material-icons-outlined"
                            color="primary"
                            >expand_circle_down</mat-icon
                          >
                        </button>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="element.status == 'Paid'">
                      <div class="row justify-content-center">
                        <a
                          class="text-success pt-2"
                          (click)="sendMessage($event, element, 'viewbill')"
                          >View</a
                        >
                        <button mat-icon-button [matMenuTriggerFor]="actions">
                          <mat-icon class="material-icons-outlined green-icon"
                            >expand_circle_down</mat-icon
                          >
                        </button>
                      </div>
                    </ng-container>

                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewbill')"
                      >
                        View
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'editbill')"
                        [disabled]="element.status != 'Pending'"
                      >
                        Edit
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'resendbill')"
                        [disabled]="element.status != 'Sent'"
                      >
                        Resend
                      </button>
                      <button
                        mat-menu-item
                        *ngIf="element.status == 'Paid'"
                        (click)="sendMessage($event, element, 'unpaybill')"
                      >
                        Unpaid
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="isInputTable == false && tableColumn.type == null"
              >
                <td
                  class="newData"
                  [ngStyle]="{
                    width: tableColumn.width ? tableColumn.width : '100vw'
                  }"
                >
                  {{
                    isNumber(
                      element[tableColumn.dataKey],
                      tableColumn.name.toLowerCase()
                    )
                      ? (element[tableColumn.dataKey] | number: "1.2-2")
                      : element[tableColumn.dataKey]
                  }}
                </td>
              </ng-container>
              <ng-container
                *ngIf="isInputTable == true && tableColumn.type == null"
              >
                <td class="newData m-0 p-0">
                  <ng-container>
                    <textarea
                      class="form-control newDataInput"
                      type="text"
                      style="height: 50px; border-color: white; resize: none"
                      value="{{
                        tableDataSource.data[element.col1 - 1][
                          'col' + (tableColumns.indexOf(tableColumn) + 2)
                        ]
                      }}"
                      (change)="
                        onInputChange(
                          element,
                          tableColumns.indexOf(tableColumn),
                          $event
                        )
                      "
                    ></textarea>
                  </ng-container>
                </td>
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #darkTitle>
            <ng-container
              *ngIf="
                mergeRowArray.includes(
                  tableDataSource.filteredData.indexOf(element)
                ) == true;
                else noMerge
              "
            >
              <ng-container *ngIf="!(mergeColumnArray.length > 1)">
                <td
                  [attr.colspan]="tableColumns.length"
                  class="rowTitle"
                  [ngStyle]="{
                    width: tableColumn.width ? tableColumn.width : '100vw'
                  }"
                  *ngIf="tableColumns.indexOf(tableColumn) == 0"
                >
                  {{ element[tableColumn.dataKey] }}
                </td>
              </ng-container>

              <ng-container *ngIf="mergeColumnArray.length > 1">
                <ng-container
                  *ngIf="
                    mergeColumnArray.includes(tableColumns.indexOf(tableColumn))
                  "
                >
                  <ng-container
                    *ngIf="
                      mergeColumnArray.includes(
                        tableColumns.indexOf(tableColumn) + 1
                      ) == false;
                      else noMerge
                    "
                  >
                    <td
                      [attr.colspan]="
                        mergeColumnArray[
                          mergeColumnArray.indexOf(
                            tableColumns.indexOf(tableColumn)
                          ) + 1
                        ] -
                        mergeColumnArray[
                          mergeColumnArray.indexOf(
                            tableColumns.indexOf(tableColumn)
                          )
                        ]
                          ? mergeColumnArray[
                              mergeColumnArray.indexOf(
                                tableColumns.indexOf(tableColumn)
                              ) + 1
                            ] -
                            mergeColumnArray[
                              mergeColumnArray.indexOf(
                                tableColumns.indexOf(tableColumn)
                              )
                            ]
                          : tableColumns.length -
                            mergeColumnArray[
                              mergeColumnArray.indexOf(
                                tableColumns.indexOf(tableColumn)
                              )
                            ]
                      "
                      class="rowTitle"
                      [ngStyle]="{
                        width: tableColumn.width ? tableColumn.width : '100vw'
                      }"
                    >
                      {{ element[tableColumn.dataKey] }}
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #noMerge>
            <td
              class="rowTitle"
              [ngStyle]="{
                width: tableColumn.width ? tableColumn.width : '100vw'
              }"
            >
              {{ element[tableColumn.dataKey] }}
            </td>
          </ng-template>
        </ng-container>
      </ng-container>
      <tbody>
        <ng-container *ngIf="showTitle">
          <ng-container *ngIf="isStickyTitle == false">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          </ng-container>
          <ng-container *ngIf="isStickyTitle == true">
            <tr
              class="sticky"
              mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></tr>
          </ng-container>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </tbody>
    </table>
  </div>

  <!-- Pagination -->
  <mat-paginator
    *ngIf="isPageable == true"
    [length]="totalItem"
    [pageSizeOptions]="paginationSizes"
    [pageSize]="defaultPageSize"
    showFirstLastButtons
    (page)="getNext($event)"
  >
  </mat-paginator>
</ng-container>
