import { TenantServiceProxy } from './../../shared/service-proxies/service-proxies';
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Settings, AppSettings } from "src/app/app.settings";
import { isPlatformBrowser } from "@angular/common";
import {
  BillServiceProxy,
  Category,
  CompanyProfile,
  CompanyProfileServiceProxy,
  Product,
  ProductDto,
  ProductServiceProxy,
  SessionServiceProxy,
  UserLoginInfoDto,
} from "src/shared/service-proxies/service-proxies";
import { Options } from "@angular-slider/ngx-slider";
import { AppService } from "../app.service";
import { ProductDialogComponent } from "../shared/products-carousel/product-dialog/product-dialog.component";
import { BehaviorSubject } from "rxjs";
import { ProductPoopupComponent } from "./product-poopup/product-poopup.component";
import { CartPopupComponent } from "./cart-popup/cart-popup.component";
import { CheckoutComponent } from "../pages/checkout/checkout.component";
import { CheckoutPopupComponent } from "./checkout-popup/checkout-popup.component";
import { SignInComponent } from "../pages/sign-in/sign-in.component";
import { CookieService } from "ngx-cookie-service";
import * as e from "express";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SearchPopupComponent } from "./search-popup/search-popup.component";
import { PickTableComponent } from './pick-table/pick-table.component';

@Component({
  selector: "app-user-store",
  templateUrl: "./user-store.component.html",
  styleUrls: ["./user-store.component.scss"],
})
export class UserStoreComponent implements OnInit {
  @ViewChild("sidenav", { static: true }) sidenav: any;
  public sidenavOpen: boolean = true;
  private sub: any;
  public viewType: string = "grid";
  public viewCol: number = 25;
  public counts = [50, 100, 500];
  public count: any;
  public sortings = [
    "Sort by Default",
    "Best match",
    "Lowest first",
    "Highest first",
  ];
  public sort: any;
  public products: Array<ProductDto> = [];
  public categories: Category[];
  public brands = [];
  public priceFrom: number = 750;
  public priceTo: number = 1599;
  public colors = [
    { name: "#5C6BC0", selected: false },
    { name: "#66BB6A", selected: false },
    { name: "#EF5350", selected: false },
    { name: "#BA68C8", selected: false },
    { name: "#FF4081", selected: false },
    { name: "#9575CD", selected: false },
    { name: "#90CAF9", selected: false },
    { name: "#B2DFDB", selected: false },
    { name: "#DCE775", selected: false },
    { name: "#FFD740", selected: false },
    { name: "#00E676", selected: false },
    { name: "#FBC02D", selected: false },
    { name: "#FF7043", selected: false },
    { name: "#F5F5F5", selected: false },
    { name: "#696969", selected: false },
  ];
  public sizes = [
    { name: "S", selected: false },
    { name: "M", selected: false },
    { name: "L", selected: false },
    { name: "XL", selected: false },
    { name: "2XL", selected: false },
    { name: "32", selected: false },
    { name: "36", selected: false },
    { name: "38", selected: false },
    { name: "46", selected: false },
    { name: "52", selected: false },
    { name: '13.3"', selected: false },
    { name: '15.4"', selected: false },
    { name: '17"', selected: false },
    { name: '21"', selected: false },
    { name: '23.4"', selected: false },
  ];
  public page: any;
  public settings: Settings;

  totalItem = 50;
  tenantId = 0;
  filterText = "";
  searchString = "";
  sortingText = "";
  maxResult = 49;
  skipCount = 0;
  selectedCategory = "";
  isSubsidized = false;
  isCertified = false;
  isIslamic = false;
  isLoanAvailable = false;

  minValue: number = 0;
  maxValue: number = 1000000;
  options: Options = {
    floor: 0,
    ceil: 10000,
  };

  companyRegNo = "";

  myCompanyObj = new BehaviorSubject<CompanyProfile>(undefined);
  myuser: UserLoginInfoDto;

  isPos = false;
  tenancyName = "";

  public slides;
  public slideSecondary
  billplzStatusId = 0;

  public featuredProducts: Array<ProductDto>;
  public onSaleProducts: Array<ProductDto>;
  public topRatedProducts: Array<ProductDto>;
  public newArrivalsProducts: Array<ProductDto>;

  table = ""

  constructor(
    public appSettings: AppSettings,
    private activatedRoute: ActivatedRoute,
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router,
    private _productService: ProductServiceProxy,
    private _companyprofileService: CompanyProfileServiceProxy,
    public _dialog: MatDialog,
    public _sessionService: SessionServiceProxy,
    private cookieService: CookieService,
    public snackBar: MatSnackBar,
    private _billService: BillServiceProxy,
    private _tenantService: TenantServiceProxy,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.settings = this.appSettings.settings;
  }
  ngOnInit() {
    this._sessionService.getCurrentLoginInformations().subscribe(result => {
      if (result.user != null) {
        this.myuser = result.user;
      }
      else {
        this.myuser = null;
        this.cookieService.deleteAll();
      }
    })
    this.count = this.counts[0];
    this.sort = this.sortings[0];

    this.activatedRoute.queryParams.subscribe(params => {
      if (params["table"]) {
        this.table = params["table"].replace(/-/g, " ");
      }
    })

    this.sub = this.activatedRoute.params.subscribe((params) => {
      if (params["companyreg"] || params["companyregPos"]) {

        if (params["companyreg"]) {
          this.companyRegNo = params["companyreg"];
        } else {
          this.companyRegNo = params["companyregPos"];
          this.isPos = true;
        }
        this._companyprofileService
          .getCompanyprofileByRegNo(this.companyRegNo)
          .subscribe((companyProfile) => {
            console.log(companyProfile)
            if (companyProfile.billplzStatusId == 2 || this.isPos == true) {
              this.billplzStatusId = 2;
            } else {
              this.billplzStatusId = 1;
            }
            this.myCompanyObj.next(companyProfile);
            this.getAllProducts();
            this.tenantId = companyProfile.tenantId;
            this._tenantService.getTenantById(this.tenantId).subscribe(
              tenantObj => {
                this.tenancyName = tenantObj.tenancyName;
              }
            )
            if (companyProfile.mainBannerUrl) {
              this.slides = [
                {
                  id: 0,
                  button: "Shop Now",
                  title: companyProfile.mainBannerTitle,
                  subtitle: companyProfile.mainBannerDescription,
                  image: companyProfile.mainBannerUrl,
                  link: "https://www.tfxislamic.com/smeonboard_v2",
                },
              ];
            } else {
              this.slides = [
                {
                  id: 0,
                  button: "Shop Now",
                  title: "WELCOME TO MY SHOP",
                  subtitle: "Biggest Sale just for today",
                  image: "assets/images/carousel/banner1.jpg",
                  link: "https://www.tfxislamic.com/smeonboard_v2",
                },
              ];
            }
            if (companyProfile.secondaryBannerUrl) {
              this.slideSecondary = [
                {
                  id: 0,
                  button: "Shop Now",
                  title: companyProfile.secondaryBannerTitle,
                  subtitle: companyProfile.secondaryBannerDescription,
                  image: companyProfile.secondaryBannerUrl,
                  link: "https://www.tfxislamic.com/smeonboard_v2",
                },
              ];
            } else {
              this.slideSecondary = null
              // [
              //   {
              //     id: 0,
              //     button: "Shop Now",
              //     title: "WELCOME TO MY SHOP",
              //     subtitle: "Biggest Sale just for today",
              //     image: "assets/images/carousel/banner1.jpg",
              //     link: "https://www.tfxislamic.com/smeonboard_v2",
              //   },
              // ];
            }
          });
      }
    });

    if (window.innerWidth < 960) {
      this.sidenavOpen = false;
    }
    if (window.innerWidth < 1280) {
      this.viewCol = 33.3;
    }

    this.getCategories();
    this.getBrands();
    this.getProducts("featured");
  }

  public getAllProducts() {
    this.searchString = this.filterText;
    this._productService
      .getProductsByCompanyRegNo(
        this.filterText,
        this.sortingText,
        this.maxResult,
        this.skipCount,
        this.companyRegNo,
        this.minValue,
        this.maxValue
      )
      .subscribe((result) => {
        console.log(result);
        this.products = result.items;
      });
  }

  public getCategories() {
    this._productService.getCategory().subscribe((data) => {
      this.categories = data;

      var lastObj = new Category();
      lastObj.name = "All";
      lastObj.id = 0;
      lastObj.hasSubCategory = false;
      lastObj.parentId = 0;
      this.categories.push(lastObj);
    });
  }

  public getBrands() {
    this.brands = this.appService.getBrands();
    this.brands.forEach((brand) => {
      brand.selected = false;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  @HostListener("window:resize")
  public onWindowResize(): void {
    window.innerWidth < 960
      ? (this.sidenavOpen = false)
      : (this.sidenavOpen = true);
    window.innerWidth < 1280 ? (this.viewCol = 33.3) : (this.viewCol = 25);
  }

  public changeCount(count) {
    this.count = count;
    this.getAllProducts();
  }

  public changeSorting(sort) {
    this.sort = sort;
  }

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
      direction: this.settings.rtl ? "rtl" : "ltr",
    });
    dialogRef.afterClosed().subscribe((product) => {
      if (product) {
        this.router.navigate(["/products", product.id, product.name]);
      }
    });
  }

  public onPageChanged(event) {
    this.page = event;
    this.getAllProducts();
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  public onChangeCategory(event) {
    if (event.target) {
      this.router.navigate(["/products", event.target.innerText.toLowerCase()]);
    }
  }

  productPopupBig(product) {
    console.log(product);
    const dialogRef = this._dialog.open(ProductPoopupComponent, {
      // width: "60%",
      panelClass: "dialog-responsive",
      data: {
        product: product,
      },
    });
    dialogRef.afterClosed().subscribe((result: ProductDto) => {
      //after close
      if (result) {
        if (result.availibilityCount - 1 >= 0 || result.isTypeSerice == true) {
          result.cartCount += 1;
          this.appService.addToCart(result);
          console.log(this.appService.Data);
        } else {
          this.snackBar.open("Sorry that product is no longer available", "×", {
            panelClass: ["error"],
            verticalPosition: "top",
            duration: 6000,
          });
        }

      }
    });
  }
  productPopupSmall(product) {
    console.log(product);
    const dialogRef = this._dialog.open(ProductPoopupComponent, {

      panelClass: "dialog-responsive",
      data: {
        product: product,
        isPos: this.isPos
      },
    });
    dialogRef.afterClosed().subscribe((result: ProductDto) => {
      //after close
      if (result) {
        if (result.availibilityCount - 1 >= 0 || result.isTypeSerice == true) {
          result.cartCount += 1;
          this.appService.addToCart(result);
          console.log(this.appService.Data);
        } else {
          this.snackBar.open("Sorry that product is no longer available", "×", {
            panelClass: ["error"],
            verticalPosition: "top",
            duration: 6000,
          });
        }

      }
    });
  }

  cartPopup() {
    this._dialog.closeAll();
    const dialogRef = this._dialog.open(CartPopupComponent, {
      width: "100%",
      maxWidth: "90vw",
      data: {
        isPos: this.isPos
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
      if (result == true) {
        if (this.isPos == false) {
          this.checkoutPopup();
        } else {
          if (this.table?.length > 0) {
            this._billService.addTableOrder(this.tenancyName, this.table, "-", this.appService.Data.cartList).subscribe(
              result => {
                this.snackBar.open("Order has been placed", "×", {
                  panelClass: ["success"],
                  verticalPosition: "top",
                  duration: 6000,
                });
                this.appService.Data.cartList = [];
                this.appService.Data.totalPrice = 0;
                window.location.reload();
              }
            )
          }
          else {
            this._billService.getTableList(this.tenancyName).subscribe(
              tablelist => {
  
                const dialogRef = this._dialog.open(PickTableComponent, {
                  disableClose: true,
                  width: "100% !important",
                  maxWidth: "90vw !important",
                  data: {
                    tablelist: tablelist,
                    tenancyName: this.tenancyName
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  //after close
                  if (result) {
                    window.location.reload();
                  }
                });
              }
            )
          }
        }
      }
    });
  }

  checkoutPopup() {
    this._dialog.closeAll();
    const dialogRef = this._dialog.open(CheckoutPopupComponent, {
      width: "100%",
      maxWidth: "90vw",
      data: {
        tenantId: this.tenantId,
        companyRegNo: this.companyRegNo
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });
  }

  signinPopup() {
    this._dialog.closeAll();
    const dialogRef = this._dialog.open(SignInComponent, {
      width: "100%",
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });

  }

  logout() {

    this.myuser = null;
    this.cookieService.deleteAll();
    // this.router.navigate(["/"]);
    window.location.reload();
  }

  searchPopup() {
    this._dialog.closeAll();
    const dialogRef = this._dialog.open(SearchPopupComponent, {
      width: "100vw",
      data: {
        filterText: this.filterText
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
      if (result) {
        this.filterText = result;
        this.getAllProducts();

      } else {
        this.filterText = "";
        this.getAllProducts();
      }
    });
  }

  public onLinkClick(e) {
    this.getProducts(e.tab.textLabel.toLowerCase());
  }

  public getProducts(type) {
    if (type == "featured" && !this.featuredProducts) {
      this._productService.getFeaturedProductsByCompanyRegNo(
        this.filterText,
        this.sortingText,
        this.maxResult,
        this.skipCount,
        this.companyRegNo,
        this.minValue,
        this.maxValue
      )
        .subscribe((result) => {
          console.log(result);
          this.featuredProducts = result.items;
        });
    }
    // if (type == "on sale" && !this.onSaleProducts) {
    //   this.appService.getProducts("on-sale").subscribe((data) => {
    //     this.onSaleProducts = data;
    //   });
    // }
    // if (type == "top rated" && !this.topRatedProducts) {
    //   this.appService.getProducts("top-rated").subscribe((data) => {
    //     this.topRatedProducts = data;
    //   });
    // }
    // if (type == "new arrivals" && !this.newArrivalsProducts) {
    //   this.appService.getProducts("new-arrivals").subscribe((data) => {
    //     this.newArrivalsProducts = data;
    //   });
    // }
  }
}