import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  token: string;

  constructor(
    private spinner: NgxSpinnerService,
    private cookieService: CookieService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.show();

    this.token = this.cookieService.get("accessToken");

    if (this.token) {
      req = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + this.token),
      });
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinner.hide();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {

        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(
          `Request for ${req.urlWithParams} failed after ${elapsed} ms.`
        );
        // debugger;
        return throwError(error);
      })
    );
  }
}
