import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-custom-table",
  templateUrl: "./custom-table.component.html",
  styleUrls: ["./custom-table.component.css"],
})
export class CustomTableComponent implements OnInit {
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];

  booltest: boolean = true;
  sortDirection = "asc";

  pageSize = "10";
  pageIndex = "0";
  dataSource;
  filterText = "";

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) matSort: MatSort;

  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() showTitle = true;
  @Input() isStickyTitle = false;

  @Input() isLeftTitle = false;
  @Input() isLeftTitleArray = false;

  @Input() isInputTable = false;
  @Input() uploadSpinner = false;
  @Input() totalItem = "100";

  @Input() tableColumns: TableColumn[];
  @Input() rowActionIcon: string;

  @Input() canEdit: boolean = false;

  @Input() paginationSizes: number[] = [5, 10, 25, 50, 100, 250, 500];

  @Input() titleRowArray: number[] = [];
  @Input() mergeRowArray: number[] = [];
  @Input() mergeColumnArray: number[] = [];

  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() buyers: any = [];

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() editRowAction: EventEmitter<any> = new EventEmitter<any>();

  @Output() messageEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() authenticationEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() uploadButtonEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() buyerDropDownEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() cancelUploadEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() paginatorEvent: EventEmitter<any> = new EventEmitter();
  @Output() uploadCfsEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteCfs: EventEmitter<any> = new EventEmitter();
  @Output() rejectInvoice: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();

  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  @Input() set inputTableDataSource(input) {
    this.tableDataSource = input;
    this.tableDataSource.sort = this.matSort;
    if (this.isPageable == false) {
      this.tableDataSource.paginator = this.matPaginator;
    }
  }
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    const columnNames = this.tableColumns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    if (this.rowActionIcon) {
      this.displayedColumns = [...columnNames, this.rowActionIcon];
    } else {
      this.displayedColumns = columnNames;
    }
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    if (this.isPageable == false) {
      this.tableDataSource.paginator = this.matPaginator;
    }
    this.tableDataSource.sort = this.matSort;
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.sort = this.matSort;
    if (this.isPageable == false) {
      this.tableDataSource.paginator = this.matPaginator;
    }
  }

  applyFilter() {
    this.searchEvent.emit(this.filterText);
  }

  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    sortParameters.active = this.tableColumns.find(
      (column) => column.name === sortParameters.active
    ).dataKey;

    // if (this.sortDirection == "asc") {
    //   this.matSort.direction = "desc";
    //   this.sortDirection = "desc";
    // } else {
    //   this.matSort.direction = "asc";
    //   this.sortDirection = "asc";
    // }

    this.sort.emit(sortParameters);

    // this.matSort.active = sortParameters.active;
    // this.matSort.disableClear = false;

    // this.tableDataSource.sort = this.matSort;
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  emitEditRowAction(element) {
    this.editRowAction.emit(element);
  }

  onInputChange(element, columnNumber, $event) {
    // if (Object.keys(element).length >= 11) {
    //   this.canUploadScannedInvoice = true;
    // }

    console.log(element);

    var returnObject = {
      element: element,
      columnNumber: columnNumber + 1,
      event: $event,
    };
    this.messageEvent.emit(returnObject);
  }

  getNumberOfKeys(element) {
    return Object.keys(element).length;
  }

  onAuthenticationClick(data) {
    var returnObject = {
      objectData: data,
    };

    this.authenticationEvent.emit(returnObject);
  }

  onRejectInvoiceClick(data) {
    var returnObject = {
      objectData: data,
    };

    this.rejectInvoice.emit(returnObject);
  }

  onUploadButtonClick(rowNumber, columnNumber, $event) {
    console.log(rowNumber, columnNumber, $event);
    var columnString = "col" + columnNumber;

    var returnObject = {
      rowNumber: rowNumber.col1,
      columnNumber: columnNumber + 1,
      event: $event,
    };
    this.uploadButtonEvent.emit(returnObject);
  }

  onBuyerChangeSelection(rowNumber, columnNumber, $event) {
    var test: object = $event.target.value;

    var columnString = "col" + columnNumber;

    var returnObject = {
      rowNumber: rowNumber.col1,
      columnNumber: columnNumber + 1,
      buyerId: $event.target.value,
    };

    this.buyerDropDownEvent.emit(returnObject);
  }

  cancelUploadClick(rowNumber, columnNumber, element) {
    this.uploadSpinner = true;

    var returnObject = {
      rowNumber: rowNumber.col1,
      columnNumber: columnNumber + 1,

      element: element,
    };

    this.cancelUploadEvent.emit(returnObject);
  }

  openWindow(fileURL) {
    window.open(fileURL, "_blank");
  }

  getNext($event) {
    this.paginatorEvent.emit($event);
  }

  uploadCfcFunction($event, element) {
    this.uploadCfsEvent.emit({
      event: $event,
      element: element,
    });
  }

  deleteCfsFunction(element) {
    this.deleteCfs.emit(element);
  }

  openLink(link) {
    window.open(link, "_blank");
  }

  sendInvitationEmail($event, element) {
    var message = {
      type: "sendEmail",
      element: element,
      event: $event,
    };

    this.messageEvent.emit(message);
  }

  sendMessageEvent($event, element, typeName) {
    var message = {
      type: typeName,
      element: element,
      event: $event,
    };

    console.log(message);

    this.messageEvent.emit(message);
  }

  sendMessage($event, element, typeName, columnName) {
    var message = {
      typeName: typeName,
      element: element,
      event: $event,
      columnName: columnName,
    };

    this.messageEvent.emit(message);
  }

  companyInfo(element) {
    var message = {
      type: "companyInfo",
      element: element,
    };

    this.messageEvent.emit(message);
  }

  accept($event, element) {
    var message = {
      type: "accept",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  reject($event, element) {
    var message = {
      type: "reject",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  differ($event, element) {
    var message = {
      type: "defer",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  edit($event, element) {
    var message = {
      type: "edit",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  deleteProgram($event, element) {
    var message = {
      type: "delete",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  viewInvoices($event, element) {
    var message = {
      type: "invoices",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  view($event, element) {
    var message = {
      type: "view",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusActive($event, element) {
    var message = {
      type: "statusActive",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusRehabilitation($event, element) {
    var message = {
      type: "statusRehabilitation",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusNonPerforming($event, element) {
    var message = {
      type: "statusNonPerforming",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusOther($event, element) {
    var message = {
      type: "statusOther",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  isNumber(val, name): boolean {
    if (typeof val === "number" && name) {
      if (name.includes("amount")) {
        return true;
      }
    }

    return false;
  }
}

export interface TableColumn {
  name: string;
  dataKey: string;
  type?: string;
  typeName?: string;
  position?: "right" | "left";
  isSortable?: boolean;
  width: string;
}
