<ng-container *ngIf="billplzStatusId == 1">
  <div class="container" style="margin-top: 20px">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <p>
          This Merchant has not been verified yet. This process might take
          several days.
        </p>
      </div>
      <div class="col text-center">Thank You for your patience</div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="billplzStatusId == 2">
  <mat-toolbar
    id="menu-toolbar"
    class="top-navbar mat-elevation-z2 app-toolbar"
    fxLayoutAlign="center center"
    [ngStyle]="{
      'background-color': '#' + (myCompanyObj?.value.brandingColor || '1976d2')
    }"
  >
    <div class="text-light row w-100" style="font-size: 15px">
      <div class="col p-0">
        <a
          class="brand-link p-0"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
        >
          <ng-container *ngIf="myCompanyObj == null">
            <img
              src="assets/images/tfx/logo.png"
              alt="Logo"
              class="brand-image rounded-circle elevation-3"
              style="opacity: 0.8"
            />
          </ng-container>
          <ng-container *ngIf="myCompanyObj | async">
            <ng-container *ngIf="myCompanyObj?.value.companyLogo != null">
              <img
                class="brand-image rounded-circle elevation-3"
                alt="Logo"
                [src]="myCompanyObj?.value.companyLogo"
                width="80"
                height="80"
              />
            </ng-container>
            <ng-container *ngIf="myCompanyObj?.value.companyLogo == null">
              <img
                src="assets/images/tfx/logo.png"
                alt="Logo"
                class="brand-image rounded-circle elevation-3"
                style="opacity: 0.8"
              />
            </ng-container>
            <div
              class="brand-text font-weight-bold d-inline-flex text-light"
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 290px;
                padding-left: 10px;
              "
            >
              {{ myCompanyObj?.value.companyName }}
            </div>
          </ng-container>
        </a>
      </div>
      <div class="d-md-block d-none col">
        <div class="text-center">
          <div class="search-bar">
            <form method="get">
              <input
                type="text"
                placeholder="Search product..."
                class="mat-elevation-z3"
                [(ngModel)]="filterText"
                name="filterText"
                (change)="getAllProducts()"
              />
              <button mat-icon-button type="button" (click)="getAllProducts()">
                <mat-icon>search</mat-icon>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="text-right">
          <div fxLayoutAlign="end" [@.disabled]="true">
            <div
              class="d-none d-md-block"
              *ngIf="myuser == null && isPos == false"
            >
              <a class="text-light" (click)="signinPopup()"> Login </a>
            </div>

            <div
              class="d-md-none"
              style="margin-top: 6px"
              *ngIf="isPos == false"
            >
              <ng-container *ngIf="myuser == null">
                <button class="col" mat-icon-button (click)="signinPopup()">
                  <mat-icon>login</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="myuser != null">
                <button class="col" mat-icon-button (click)="logout()">
                  <mat-icon>logout</mat-icon>
                </button>
              </ng-container>
            </div>

            <div *ngIf="myuser != null || isPos == true">
              <div class="row">
                <div class="col-6 d-none d-md-block">
                  <button
                    mat-button
                    (click)="cartPopup()"
                    class="flex-row-button mt-1"
                  >
                    <mat-icon class="mat-icon-md">shopping_cart</mat-icon>
                    <span
                      *ngIf="appService.Data.totalCartCount > 0"
                      class="cart-items-count"
                      >{{ appService.Data.totalCartCount }}</span
                    >
                    <div
                      fxLayout="column"
                      fxLayoutAlign="center center"
                      fxShow="false"
                      fxShow.gt-xs
                      class="top-cart"
                    >
                      <span>Shopping Cart</span>
                      <span *ngIf="appService.Data.totalCartCount > 0">
                        <bdi
                          >{{ appService.Data.totalCartCount }} item<span
                            *ngIf="appService.Data.totalCartCount > 1"
                            >s</span
                          >
                          - $<span>{{
                            appService.Data.totalPrice | number : "1.2-2"
                          }}</span>
                        </bdi>
                      </span>
                    </div>
                  </button>
                </div>
                <div
                  *ngIf="myuser == null"
                  class="col-6 text-right d-none d-md-block"
                  style="margin-top: 5px"
                >
                  <button mat-button (click)="signinPopup()"> LOGIN</button>
                </div>
                <div
                  *ngIf="myuser != null"
                  class="col-6 text-right d-none d-md-block"
                  style="margin-top: 5px"
                >
                  <button mat-button (click)="logout()"> LOGOUT</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>

  <div class="container pt-3">
    <ng-container *ngIf="slides != null">
      <app-main-carousel
        *ngIf="searchString == ''"
        [slides]="slides"
        [showButton]="false"
      ></app-main-carousel>
    </ng-container>

    <div
      *ngIf="featuredProducts?.length > 0"
      class="products-tabs d-none d-lg-block"
    >
      <mat-tab-group mat-stretch-tabs (selectedTabChange)="onLinkClick($event)">
        <mat-tab label="Featured">
          <app-products-carousel
            [products]="featuredProducts"
          ></app-products-carousel>
        </mat-tab>
        <!-- <mat-tab label="On Sale">
          <app-products-carousel
            [products]="onSaleProducts"
          ></app-products-carousel>
        </mat-tab>
        <mat-tab label="Top Rated">
          <app-products-carousel
            [products]="topRatedProducts"
          ></app-products-carousel>
        </mat-tab>
        <mat-tab label="New Arrivals">
          <app-products-carousel
            [products]="newArrivalsProducts"
          ></app-products-carousel>
        </mat-tab> -->
      </mat-tab-group>
    </div>

    <ng-container *ngIf="slideSecondary != null">
      <app-secondary-carousel
        *ngIf="searchString == ''"
        [slides]="slideSecondary"
        [showButton]="false"
      ></app-secondary-carousel>
    </ng-container>

    <div class="d-none d-lg-block">
      <mat-tab-group mat-stretch-tabs>
        <mat-tab label="All Products"></mat-tab>
      </mat-tab-group>
    </div>

    <mat-sidenav-container>
      <mat-sidenav-content class="all-products">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="space-between center"
          class="filter-row mat-elevation-z1 text-muted"
        >
          <div class="d-none d-md-block">
            <a
              mat-button
              [matMenuTriggerFor]="sortMenu"
              #sortMenuTrigger="matMenuTrigger"
            >
              {{ sort
              }}<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
            </a>
            <mat-menu
              #sortMenu="matMenu"
              xPosition="before"
              class="app-dropdown"
              overlapTrigger="true"
            >
              <span (mouseleave)="sortMenuTrigger.closeMenu()">
                <button
                  mat-menu-item
                  *ngFor="let sort of sortings"
                  (click)="changeSorting(sort)"
                >
                  <span>{{ sort }}</span>
                </button>
              </span>
            </mat-menu>
          </div>
          <div class="d-none d-md-block">
            <a
              mat-button
              [matMenuTriggerFor]="countsMenu"
              #countsMenuTrigger="matMenuTrigger"
            >
              Show {{ count
              }}<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
            </a>
            <mat-menu
              #countsMenu="matMenu"
              xPosition="before"
              class="app-dropdown"
              overlapTrigger="true"
            >
              <span (mouseleave)="countsMenuTrigger.closeMenu()">
                <button
                  mat-menu-item
                  *ngFor="let count of counts"
                  (click)="changeCount(count)"
                >
                  <span>{{ count }}</span>
                </button>
              </span>
            </mat-menu>
            <button mat-icon-button (click)="changeViewType('list', 100)">
              <mat-icon>view_list</mat-icon>
            </button>
            <button mat-icon-button (click)="changeViewType('grid', 33.3)">
              <mat-icon>view_module</mat-icon>
            </button>
            <button mat-icon-button (click)="changeViewType('grid', 25)">
              <mat-icon>view_comfy</mat-icon>
            </button>
          </div>
        </div>

        <div
          *ngIf="viewType == 'grid'"
          fxLayout="row wrap"
          class="products-wrapper"
        >
          <div
            *ngFor="
              let product of products
                | paginate : { itemsPerPage: count, currentPage: page }
            "
            fxFlex="100"
            [fxFlex.gt-sm]="viewCol"
            fxFlex.sm="50"
            fxFlex.xs="50"
            class="col"
          >
            <mat-card class="product-item text-center" style="height: 100%">
              <mat-chip-list *ngIf="product.discount">
                <mat-chip color="warn" selected="true"
                  >{{ product.discount }}% OFF</mat-chip
                >
              </mat-chip-list>
              <a
                (click)="productPopupBig(product)"
                class="d-none d-md-block image-link"
              >
                <div *ngIf="product.images.length > 0">
                  <img
                    class="fitWidthImgBig"
                    [src]="product.images[0]?.medium"
                    alt=""
                    style="height: auto; width: auto"
                  />
                </div>
                <div *ngIf="product.images.length == 0">
                  <img
                    class="fitWidthImgBig"
                    src="assets/images/tfx/emptyImg.png"
                    alt=""
                    style="height: auto; width: auto; opacity: 0.2"
                  />
                </div>
                <div class="title text-truncate mt-1">
                  {{ product.name }}
                </div>
              </a>
              <a
                (click)="productPopupSmall(product)"
                class="d-md-none image-link"
              >
                <div *ngIf="product.images.length > 0">
                  <img
                    class="fitWidthImgSmall"
                    [src]="product.images[0]?.medium"
                    alt=""
                    style="height: 100px; width: auto"
                  />
                </div>
                <div *ngIf="product.images.length == 0">
                  <img
                    class="fitWidthImgSmall"
                    src="assets/images/tfx/emptyImg.png"
                    alt=""
                    style="height: auto; width: auto; opacity: 0.2"
                  />
                </div>
                <p [line-truncation]="2" class="title text-truncate">
                  {{ product.name }}
                </p>
              </a>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="prices"
              >
                <div fxLayout="column" fxLayoutAlign="center start">
                  <p class="old-price text-muted">
                    <span
                      *ngIf="
                        product.oldPrice && product.oldPrice !== product.price
                      "
                      >RM{{ product.oldPrice | number : "1.2-2" }}</span
                    >
                  </p>
                  <p class="text-primary font-weight-bold">
                    RM{{ product.price | number : "1.2-2" }}
                  </p>
                </div>
                <div fxLayout="column" fxLayoutAlign="center end">
                  <p class="new-price pl-3" style="font-size: 2vh !important">
                    {{
                      product.availibilityCount > 0 || product.isTypeSerice
                        ? ""
                        : "No Stock"
                    }}
                  </p>
                </div>
              </div>
            </mat-card>
          </div>
        </div>

        <div
          *ngIf="viewType == 'list'"
          fxLayout="row wrap"
          class="products-wrapper"
        >
          <div
            *ngFor="
              let product of products
                | paginate : { itemsPerPage: count, currentPage: page }
            "
            fxFlex="100"
            class="col"
          >
            <mat-card class="product-item">
              <div fxLayout="row wrap">
                <div fxFlex="100" fxFlex.gt-xs="40" class="p-2">
                  <mat-chip-list *ngIf="product.discount" class="list-view">
                    <mat-chip color="warn" selected="true"
                      >{{ product.discount }}% OFF</mat-chip
                    >
                  </mat-chip-list>
                  <a (click)="productPopupBig(product)" class="image-link">
                    <div
                      *ngIf="product.images.length > 0"
                      style="height: auto; width: auto"
                    >
                      <img
                        class="fitWidthImgBig"
                        [src]="product.images[0]?.medium"
                        alt=""
                        style="height: auto; width: auto"
                      />
                    </div>
                    <div
                      *ngIf="product.images.length == 0"
                      style="height: auto; width: auto"
                    >
                      <img
                        class="fitWidthImgBig"
                        src="assets/images/tfx/emptyImg.png"
                        alt=""
                        style="height: auto; width: auto; opacity: 0.2"
                      />
                    </div>
                  </a>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="60" class="mt-1 p-2">
                  <a (click)="productPopupBig(product)" class="title">
                    {{ product.name }}
                  </a>
                  <p class="py-1 text-muted lh">{{ product.description }}</p>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    class="prices"
                  >
                    <div fxLayout="column" fxLayoutAlign="center start">
                      <p class="old-price text-muted">
                        <span
                          *ngIf="
                            product.oldPrice &&
                            product.oldPrice !== product.price
                          "
                          >RM{{ product.oldPrice | number : "1.2-2" }}</span
                        >
                      </p>
                      <p class="text-primary font-weight-bold">
                        RM{{ product.price | number : "1.2-2" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>

        <div *ngIf="products.length > 0" fxLayout="row wrap">
          <div fxFlex="100">
            <mat-card class="p-0 text-center">
              <pagination-controls
                class="product-pagination"
                autoHide="true"
                maxSize="5"
                (pageChange)="onPageChanged($event)"
              ></pagination-controls>
            </mat-card>
          </div>
        </div>
        <div style="height: 60px !important"></div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

  <footer
    class="d-none d-md-block footer"
    [ngStyle]="{
      'background-color': '#' + (myCompanyObj?.value.brandingColor || '1976d2')
    }"
  >
    <div class="row justify-content-center text-center">
      <h5 class="text-white">
        <img
          alt="Logo"
          [src]="
            myCompanyObj?.value?.companyLogo || 'assets/images/tfx/logo.png'
          "
          class="brand-image rounded-circle elevation-3"
          style="opacity: 0.8"
          width="40"
          height="40"
          onError="assets/images/tfx/logo.png"
        />
        &nbsp;&nbsp;&nbsp;{{ myCompanyObj?.value.companyName }}
      </h5>
      <div class="pb-3">
        <a
          [href]="myCompanyObj?.value?.websiteLink"
          target="_blank"
          class="text-white m-2"
          [class.disabled-link]="myCompanyObj?.value?.websiteLink == null"
        >
          <i class="fas fa-2x fa-home"></i>
        </a>
        <a
          [href]="myCompanyObj?.value?.facebookLink"
          target="_blank"
          class="text-white m-2"
          [class.disabled-link]="myCompanyObj?.value?.facebookLink == null"
        >
          <i class="fab fa-2x fa-facebook"></i>
        </a>
        <a
          [href]="myCompanyObj?.value?.youtubeLink"
          target="_blank"
          class="text-white m-2"
          [class.disabled-link]="myCompanyObj?.value?.youtubeLink == null"
        >
          <i class="fab fa-2x fa-youtube"></i>
        </a>
        <a
          [href]="myCompanyObj?.value?.whatsappLink"
          target="_blank"
          class="text-white m-2"
          [class.disabled-link]="myCompanyObj?.value?.whatsappLink == null"
        >
          <i class="fab fa-2x fa-whatsapp"></i>
        </a>
      </div>
    </div>
  </footer>
  <footer
    class="d-md-none fixedFooter"
    style="z-index: 1"
    [ngStyle]="{
      'background-color': '#' + (myCompanyObj?.value.brandingColor || '1976d2')
    }"
  >
    <div class="row justify-content-center">
      <button class="col" mat-icon-button (click)="searchPopup()">
        <mat-icon>search</mat-icon>
        {{ filterText | slice : 0 : 10 }}
      </button>
      <div class="col-6">
        <button mat-button (click)="cartPopup()" class="flex-row-button mt-1">
          <mat-icon class="mat-icon-md">shopping_cart</mat-icon>
          <span
            *ngIf="appService.Data.totalCartCount > 0"
            class="cart-items-count"
            >{{ appService.Data.totalCartCount }}</span
          >
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            fxShow="false"
            fxShow.gt-xs
            class="top-cart"
          >
            <span *ngIf="appService.Data.totalCartCount > 0">
              <bdi
                >{{ appService.Data.totalCartCount }} item<span
                  *ngIf="appService.Data.totalCartCount > 1"
                  >s</span
                >
                - $<span>{{
                  appService.Data.totalPrice | number : "1.2-2"
                }}</span>
              </bdi>
            </span>
          </div>
        </button>
      </div>

      <div
        class="col"
        style="margin-top: 6px"
        *ngIf="myCompanyObj?.value?.whatsappLink?.length > 0"
      >
        <a
          [href]="myCompanyObj?.value?.whatsappLink"
          target="_blank"
          class="text-white"
          [class.disabled-link]="myCompanyObj?.value?.whatsappLink == null"
        >
          <i class="fab fa-2x fa-whatsapp"></i>
        </a>
      </div>
    </div>
  </footer>
</ng-container>
