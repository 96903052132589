import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Settings, AppSettings } from "../app.settings";
import { AppService } from "../app.service";
import { SidenavMenuService } from "../theme/components/sidenav-menu/sidenav-menu.service";
import { isPlatformBrowser } from "@angular/common";
import {
  CartDto,
  Category,
  ProductServiceProxy,
  SessionServiceProxy,
  TenantLoginInfoDto,
  UserLoginInfoDto,
} from "src/shared/service-proxies/service-proxies";
import { SignInComponent } from "./sign-in/sign-in.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [SidenavMenuService],
})
export class PagesComponent implements OnInit {
  public showBackToTop: boolean = false;
  public categories: Category[];
  public category: Category;
  public sidenavMenuItems: Array<any>;
  searchString = "";

  @ViewChild("sidenav", { static: true }) sidenav: any;

  myuser: UserLoginInfoDto;
  mytenant: TenantLoginInfoDto;

  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    public router: Router,
    private _productService: ProductServiceProxy,
    private _sessionService: SessionServiceProxy,
    @Inject(PLATFORM_ID) private platformId: Object,
    public _dialog: MatDialog,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this._sessionService.getCurrentLoginInformations().subscribe(result => {
      this.myuser = result.user;
      this.mytenant = result.tenant;
    })

    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    setTimeout(() => {
      this.settings.theme = "blue";
    });

    // this.settings.mainToolbarFixed = true;

  }

  public getCategories() {
    var firstCat = new Category();
    firstCat.name = "All Products";
    firstCat.id = 0;
    firstCat.parentId = 0;
    firstCat.hasSubCategory = false;

    this._productService.getCategory().subscribe((data) => {
      this.categories = data;
      this.categories.splice(0, 0, firstCat);

      this.category = data[0];
    });
  }

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(
        (category) => category.name == event.target.innerText
      )[0];

      if (this.category.id != 0) {
        this.router.navigate([
          "/products/search",
          this.category.name,
          this.searchString,
        ]);
      } else {
        this.router.navigate(["/products/search", "all", this.searchString]);
      }
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public remove(product: CartDto) {
    const index: number = this.appService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.appService.Data.cartList.splice(index, 1);
      this.appService.Data.totalPrice =
        this.appService.Data.totalPrice - product.unitPrice * product.quantity;
      this.appService.Data.totalCartCount =
        this.appService.Data.totalCartCount - product.quantity;
      this.appService.resetProductCartCount(product);
    }
  }

  public clear() {
    this.appService.Data.cartList.forEach((product) => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public search($event) {
    this.searchString = $event.target.value;

    if (this.category.id != 0) {
      this.router.navigate([
        "/products/search",
        this.category.name,
        this.searchString,
      ]);
    } else {
      this.router.navigate(["/products/search", "all", this.searchString]);
    }
  }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    const scrollTop = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    let header_toolbar = document.getElementById("header-toolbar");
    if (header_toolbar) {
      if (scrollTop >= header_toolbar.clientHeight) {
        this.settings.mainToolbarFixed = true;
      } else {
        if (!document.documentElement.classList.contains("cdk-global-scrollblock")) {
          this.settings.mainToolbarFixed = false;
        }
      }
    } else {
      this.settings.mainToolbarFixed = true;
    }
    $event.target.documentElement.scrollTop > 50
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(
      this.sidenavMenuService.getSidenavMenuItems()
    );
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  signinPopup() {
    this._dialog.closeAll();
    const dialogRef = this._dialog.open(SignInComponent);
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });

  }
}
