<footer class="bg-primary footer">
  <div class="row justify-content-center">
    <div class="col-6 footer-newsletter">

      <div class="text-center text-lg-start">
        <a class="btn-get-started scrollto" href="https://app.tfxebilling.com/account/onboarding">
          Join TFX Marketplace
        </a>
      </div>
    </div>
  </div>

  <div
    class="row justify-content-center copyright secondary-color theme-container text-center"
  >
    <p ngClass.xs="m-2">Copyright © TFX ISLAMIC SDN BHD</p>
  </div>
</footer>
