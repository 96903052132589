import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from "@agm/core";

import { OverlayContainer, Overlay } from "@angular/cdk/overlay";
import { MAT_MENU_SCROLL_STRATEGY } from "@angular/material/menu";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { menuScrollStrategy } from "./theme/utils/scroll-strategy";

import { environment } from "src/environments/environment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    environment.url + "/assets/i18n/",
    ".json"
  );
}

import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { TopMenuComponent } from "./theme/components/top-menu/top-menu.component";
import { MenuComponent } from "./theme/components/menu/menu.component";
import { SidenavMenuComponent } from "./theme/components/sidenav-menu/sidenav-menu.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";

import { AppSettings } from "./app.settings";
import { AppService } from "./app.service";
import { AppInterceptor } from "./theme/utils/app-interceptor";
import { OptionsComponent } from "./theme/components/options/options.component";
import { FooterComponent } from "./theme/components/footer/footer.component";
import { ServiceProxyModule } from "../shared/service-proxies/service-proxy.module";
import { API_BASE_URL } from "src/shared/service-proxies/service-proxies";
import { CookieService } from "ngx-cookie-service";
import { UserStoreComponent } from "./user-store/user-store.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SwiperModule } from "ngx-swiper-wrapper";
import { NgxPaginationModule } from "ngx-pagination";
import { PipesModule } from "./theme/pipes/pipes.module";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ProductPoopupComponent } from "./user-store/product-poopup/product-poopup.component";
import { ProductZoomComponent } from "./pages/products/product/product-zoom/product-zoom.component";
import { ProductsModule } from "./pages/products/products.module";
import { CartPopupComponent } from "./user-store/cart-popup/cart-popup.component";
import { CheckoutPopupComponent } from "./user-store/checkout-popup/checkout-popup.component";
import { SignInComponent } from "./pages/sign-in/sign-in.component";
import { SearchPopupComponent } from './user-store/search-popup/search-popup.component';
import { ResetPasswordComponent } from "./pages/sign-in/reset-password/reset-password.component";
import { LineTruncationDirective, LineTruncationLibModule } from "ngx-line-truncation";
import { PickTableComponent } from './user-store/pick-table/pick-table.component';


@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ServiceProxyModule,
    TranslateModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBt39FP8Bz5D5rT7hc5PkUatXfeGxGczns",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxPaginationModule,
    PipesModule,
    NgxSliderModule,
    LineTruncationLibModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    UserStoreComponent,
    ProductPoopupComponent,
    CartPopupComponent,
    CheckoutPopupComponent,
    SearchPopupComponent,
    PickTableComponent,
  ],
  providers: [
    AppSettings,
    AppService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay],
    },
    { provide: API_BASE_URL, useFactory: () => environment.remoteServiceBaseUrl },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    CookieService,
  ],
  entryComponents: [
    ProductPoopupComponent,
    CartPopupComponent,
    CheckoutPopupComponent,
    SearchPopupComponent,
    ResetPasswordComponent,
    PickTableComponent
  ],
  bootstrap: [AppComponent],
  exports: [
    LineTruncationDirective
  ]
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
