import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-secondary-carousel',
  templateUrl: './secondary-carousel.component.html',
  styleUrls: ['./secondary-carousel.component.scss']
})
export class SecondaryCarouselComponent implements OnInit {
  @Input("slides") slides: Array<any> = [];

  @Input() showButton: boolean = true;

  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };

  constructor(private _route: Router) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
      },
      speed: 500,
      effect: "slide",
    };
  }

  navigate(slide) {
    console.log(slide);
    if (slide.id != 0) {
      this._route.navigate(["/products/search", "all", ""]);
    } else {
      window.location.href = slide.link;
    }
  }
}
