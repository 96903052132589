<div
  class="row w-100"
  style="width: 100vw !important"
  *ngIf="pickedTable == null"
>
  <div class="col ml-4 text-left">
    <label>Please Pick Your Table Number</label>
  </div>
  <div class="col mr-2 text-right">
    <button
      mat-button
      class="btn btn-secondary kt-font-bold kt-font-transform-u mr-2"
      type="button"
      (click)="closeFunction(false)"
    >
      Close
    </button>
  </div>
</div>
<mat-dialog-content>
  <div class="row justify-content-center w-100">
    <ng-container *ngIf="pickedTable == null">
      <div class="col-12 mt-2" *ngFor="let table of myData.tablelist">
        <button
          mat-flat-button
          class="w-100"
          color="primary"
          style="height: 50px; margin-bottom: 18px; margin-right: 5px"
          (click)="addFunction(table)"
        >
          {{ table }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="pickedTable != null">
      <ng-container *ngIf="isConfirm == false">
        <div class="col-12 m-2 mb-4 text-center">
          <p>
            You have picked {{ pickedTable }} . <br />
            Please confirm and Send Order to the kitchen.
          </p>
        </div>
        <div class="col-6">
          <button
            mat-button
            class="btn btn-secondary kt-font-bold kt-font-transform-u mr-2"
            type="button"
            (click)="cancel()"
          >
            Cancel
          </button>
        </div>
        <div class="col-6">
          <button
            mat-flat-button
            class="w-100"
            color="primary"
            style="height: 50px; margin-bottom: 18px; margin-right: 5px"
            (click)="confirmClick()"
          >
            Confirm
          </button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isConfirm == true">
      <div class="col-12 m-2 mb-4 text-center">
        <p>
          We have received your order. <br />
          Thank you for your order
        </p>
      </div>
      <div class="col-6">
        <button
          mat-flat-button
          class="w-100"
          color="primary"
          style="height: 50px; margin-bottom: 18px; margin-right: 5px"
          (click)="closeFunction(true)"
        >
          OK
        </button>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
