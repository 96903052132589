import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductDto, SessionServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { Data, AppService } from '../../app.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: ProductDto;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count: number = 1;
  public align = 'center center';
  constructor(
    public appService: AppService,
    public snackBar: MatSnackBar,
    private _sessionService: SessionServiceProxy) { }

  ngOnInit() {
    if (this.product) {
      if (this.product.cartCount > 0) {
        this.count = this.product.cartCount;
      }
    }
    this.layoutAlign();
  }

  public layoutAlign() {
    if (this.type == 'all') {
      this.align = 'space-between center';
    }
    else if (this.type == 'wish') {
      this.align = 'start center';
    }
    else {
      this.align = 'center center';
    }
  }



  public increment(count) {
    if (this.count < this.product.availibilityCount) {
      this.count++;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.price
      }
      this.changeQuantity(obj);
    }
    else {
      this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }

  public decrement(count) {
    if (this.count > 1) {
      this.count--;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.price
      }
      this.changeQuantity(obj);
    }
  }

  public addToCompare(product: ProductDto) {
    this.appService.addToCompare(product);
  }

  public addToWishList(product: ProductDto) {
    this.appService.addToWishList(product);
  }

  public addToCart(product: ProductDto) {
    this._sessionService.getCurrentLoginInformations().subscribe(result => {
      if (result.tenant) {
        let currentProduct = this.appService.Data.cartList.filter(item => item.productId == product.id)[0];
        if (currentProduct) {
          if ((currentProduct.quantity + this.count) <= this.product.availibilityCount) {
            product.cartCount = currentProduct.quantity + this.count;
          }
          else {
            this.snackBar.open('You can not add more items than available. In stock ' + this.product.availibilityCount + ' items and you already added ' + currentProduct.quantity + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            return false;
          }
        }
        else {
          product.cartCount = this.count;
        }
        this.appService.addToCart(product);
      } else {
        this.snackBar.open('Please log in first', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    })
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

}