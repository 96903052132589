<mat-dialog-content class="py-0">
  <div fxLayout="row wrap" class="p-0 m-0">
    <div fxFlex="100" fxFlex.gt-md="45">
      <button
        *ngIf="myData.product.id == null"
        mat-button
        class="mb-2"
        [routerLink]="['/app/products']"
      >
        <div class="row justify-content-center">
          <mat-icon class="mr-1 pt-1"> arrow_back </mat-icon>
          <div>Back</div>
        </div>
      </button>
      <mat-card class="product-image">
        <mat-chip-list *ngIf="myData.product?.discount">
          <mat-chip color="warn" selected="true"
            >{{ myData.product?.discount }}% OFF</mat-chip
          >
        </mat-chip-list>
        <button
          mat-icon-button
          (click)="openZoomViewer()"
          fxHide="false"
          fxHide.gt-md
        >
          <mat-icon>fullscreen</mat-icon>
        </button>
        <img
          [src]="image ? image : 'assets/images/tfx/emptyImg.png'"
          (mousemove)="onMouseMove($event)"
          (mouseleave)="onMouseLeave($event)"
        />
      </mat-card>
      <div class="small-carousel">
        <div class="swiper-container" [swiper]="config">
          <div class="swiper-wrapper">
            <div
              *ngFor="let image of myData.product?.images"
              class="swiper-slide"
            >
              <mat-card (click)="selectImage(image)" class="p-1">
                <img [src]="image.small" />
              </mat-card>
            </div>
          </div>
          <button mat-icon-button class="swiper-button-prev swipe-arrow">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button mat-icon-button class="swiper-button-next swipe-arrow">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div
      fxFlex="100"
      fxFlex.gt-md="55"
      ngClass.gt-sm="px-3 m-0"
      ngClass.sm="mt-2"
      ngClass.xs="mt-2"
    >
      <div #zoomViewer fxShow="false" fxShow.gt-md>
        <mat-card
          *ngIf="zoomImage"
          class="zoom-viewer mat-elevation-z18"
          [ngStyle]="{ 'background-image': 'url(' + zoomImage + ')' }"
        ></mat-card>
      </div>

      <h2>{{ myData.product?.name }}</h2>
      <div class="py-1 lh">
        <p>
          <span class="text-muted fw-500">Category: </span
          ><span>{{ myData.product?.categoryId }}</span>
        </p>
        <p>
          <span class="text-muted fw-500">Availibility: </span
          ><span>{{
            myData.product?.availibilityCount > 0 ? "In stock" : "Unavailable"
          }}</span>
        </p>
      </div>
      <div class="py-1">
        <app-rating
          [ratingsCount]="myData.product?.ratingsCount"
          [ratingsValue]="myData.product?.ratingsValue"
          [direction]="'row'"
        ></app-rating>
      </div>
      <p class="py-1 text-muted lh">{{ myData.product?.description }}</p>

      <div class="divider mt-1"></div>
      <div class="py-2">
        <h3 class="old-price text-muted">
          <span
            *ngIf="
              myData.product?.oldPrice != myData.product?.price &&
              myData.product?.oldPrice != 0
            "
            >RM{{ myData.product?.oldPrice | number : "1.2-2" }}</span
          >
        </h3>
        <h2 class="new-price">RM{{ myData.product?.price }}</h2>
      </div>

      <div class="divider"></div>
    </div>
  </div>

  <div fxLayout="row wrap" class="p-1 mt-2">
    <div fxFlex="100">
      <mat-card>
        <mat-tab-group [@.disabled]="true" [selectedIndex]="0">
          <mat-tab label="Description">
            <div class="full-desc lh">
              <p>
                {{ myData.product?.fullDescription }}
              </p>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>

  <div style="height: 10vh !important"></div>

  <div class="floating d-sm-none">
    <div class="row justify-content-center">
      <div class="col-5">
        <button
          class="w-100"
          mat-raised-button
          type="button"
          (click)="closeFunction()"
          color="warn"
        >
          Close
        </button>
      </div>

      <div class="col-5">
        <button
          class="w-100"
          mat-raised-button
          type="button"
          color="primary"
          (click)="addFunction()"
        >
          Add To Cart
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div
  mat-dialog-actions
  class="row justify-content-center mb-3 d-none d-sm-block"
  style="margin-left: 50px !important;"
>
  <button
    mat-raised-button
    class="col-5"
    type="button"
    (click)="closeFunction()"
  >
    Close
  </button>
  <button
    mat-raised-button
    class="col-5"
    type="button"
    color="primary"
    (click)="addFunction()"
  >
    Add To Cart
  </button>
</div>
