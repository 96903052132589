import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AppService } from "../../../app.service";
import { Settings, AppSettings } from "../../../app.settings";
import { SessionServiceProxy, UserLoginInfoDto } from "src/shared/service-proxies/service-proxies";

@Component({
  selector: "app-top-menu",
  templateUrl: "./top-menu.component.html",
})
export class TopMenuComponent implements OnInit {
  public currencies = ["USD", "EUR"];
  public currency: any;

  myUser: UserLoginInfoDto

  public settings: Settings;
  constructor(
    private _cookieService: CookieService,
    public appSettings: AppSettings,
    public appService: AppService,
    public translateService: TranslateService,
    private router: Router,
    private _sessionService: SessionServiceProxy,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this._sessionService.getCurrentLoginInformations().subscribe(result => {
      this.myUser = result.user;
    })
    this.currency = this.currencies[0];
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }

  public changeLang(lang: string) {
    this.translateService.use(lang);
  }

  logout() {
    this._cookieService.deleteAll();
    this.router.navigate(["/"]);
    window.location.reload();
  }

  public getLangText(lang) {
    if (lang == "de") {
      return "German";
    } else if (lang == "fr") {
      return "French";
    } else if (lang == "ru") {
      return "Russian";
    } else if (lang == "tr") {
      return "Turkish";
    } else {
      return "English";
    }
  }
}
