import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { Data, AppService } from '../../app.service';
import { Settings, AppSettings } from 'src/app/app.settings';
import { ProductDto } from 'src/shared/service-proxies/service-proxies';
import { ProductPoopupComponent } from 'src/app/user-store/product-poopup/product-poopup.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {

  @Input('products') products: Array<ProductDto> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router,
    public _dialog: MatDialog,
    public snackBar: MatSnackBar,) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 2,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: 5
        }
      }
    }
  }

  productPopupBig(product) {
    console.log(product);
    const dialogRef = this._dialog.open(ProductPoopupComponent, {
      // width: "60%",
      panelClass: "dialog-responsive",
      data: {
        product: product,
      },
    });
    dialogRef.afterClosed().subscribe((result: ProductDto) => {
      //after close
      if (result) {
        if (result.availibilityCount - 1 >= 0 || result.isTypeSerice == true) {
          result.cartCount += 1;
          this.appService.addToCart(result);
          this.dialog.closeAll();
        } else {
          this.snackBar.open("Sorry that product is no longer available", "×", {
            panelClass: ["error"],
            verticalPosition: "top",
            duration: 6000,
          });
        }

      }
    });
  }

  productPopupSmall(product) {
    console.log(product);
    const dialogRef = this._dialog.open(ProductPoopupComponent, {
      panelClass: "dialog-responsive",
      data: {
        product: product,
      },
    });
    dialogRef.afterClosed().subscribe((result: ProductDto) => {
      //after close
      if (result) {
        if (result.availibilityCount - 1 >= 0 || result.isTypeSerice == true) {
          result.cartCount += 1;
          this.appService.addToCart(result);
          this.dialog.closeAll();
        } else {
          this.snackBar.open("Sorry that product is no longer available", "×", {
            panelClass: ["error"],
            verticalPosition: "top",
            duration: 6000,
          });
        }

      }
    });
  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: 'product-dialog',
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

}