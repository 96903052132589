import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss']
})
export class SearchPopupComponent implements OnInit {

  filterText = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<SearchPopupComponent>,
  ) {
  }

  ngOnInit(): void {
    this.filterText = this.myData.filterText;
  }

  searchFunction() {
    this.dialogRef.close(this.filterText);
  }


}

export interface DialogData {
  filterText: string;
}