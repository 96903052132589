<mat-sidenav-container>
  <mat-sidenav
    [opened]="false"
    mode="over"
    #sidenav
    class="sidenav mat-elevation-z6"
  >
    <button
      mat-icon-button
      color="warn"
      class="close"
      (click)="sidenav.close()"
    >
      <mat-icon color="warn">close</mat-icon>
    </button>
    <div class="divider"></div>
    <app-sidenav-menu
      [menuItems]="sidenavMenuItems"
      [menuParentId]="0"
    ></app-sidenav-menu>
    <div class="divider"></div>
    <div class="text-center py-2">
      <svg class="social-icon" viewBox="0 0 24 24">
        <path
          d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"
        />
      </svg>
      <svg class="social-icon" viewBox="0 0 24 24">
        <path
          d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z"
        />
      </svg>
      <svg class="social-icon" viewBox="0 0 24 24">
        <path
          d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M19.5,12H18V10.5H17V12H15.5V13H17V14.5H18V13H19.5V12M9.65,11.36V12.9H12.22C12.09,13.54 11.45,14.83 9.65,14.83C8.11,14.83 6.89,13.54 6.89,12C6.89,10.46 8.11,9.17 9.65,9.17C10.55,9.17 11.13,9.56 11.45,9.88L12.67,8.72C11.9,7.95 10.87,7.5 9.65,7.5C7.14,7.5 5.15,9.5 5.15,12C5.15,14.5 7.14,16.5 9.65,16.5C12.22,16.5 13.96,14.7 13.96,12.13C13.96,11.81 13.96,11.61 13.89,11.36H9.65Z"
        />
      </svg>
    </div>
  </mat-sidenav>

  <mat-toolbar color="primary" id="header-toolbar">
    <mat-toolbar-row
      fxLayoutAlign="space-between center"
      class="top-toolbar theme-container"
    >
      <span fxHide="false" fxHide.gt-sm>
        <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
          <mat-icon>menu</mat-icon>
        </button>
      </span>
      <span fxShow="false" fxShow.gt-xs fxLayoutAlign="center center">
        <!-- <mat-icon class="mat-icon-sm">call</mat-icon> Hotline: (+100) 123 456
        7890 -->
      </span>
      <ng-container *ngIf="myuser != null">
        <span>{{ "WELCOME" | translate }}</span>
        <app-top-menu></app-top-menu>
      </ng-container>
      <ng-container *ngIf="myuser == null">
        <span>{{ "WELCOME" | translate }}</span>
        <div class="top-menu">
          <a mat-button (click)="signinPopup()">Login</a>
        </div>
      </ng-container>
    </mat-toolbar-row>
    <mat-toolbar-row
      fxLayoutAlign="space-between center"
      class="logo-toolbar theme-container"
    >
      <div fxFlex fxShow="false" fxShow.gt-sm>
        <div method="get" class="search-form" fxLayout="row">
          <button
            mat-raised-button
            [matMenuTriggerFor]="categoriesMenu"
            #categoriesMenuTrigger="matMenuTrigger"
            type="button"
            class="mat-elevation-z0 categories text-truncate text-muted"
          >
            {{ category?.name }}<mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu
            #categoriesMenu="matMenu"
            [overlapTrigger]="false"
            xPosition="before"
            class="categories-dropdown"
          >
            <span (mouseleave)="categoriesMenuTrigger.closeMenu()">
              <app-category-list
                [categories]="categories"
                [categoryParentId]="0"
                (change)="changeCategory($event)"
              ></app-category-list>
            </span>
          </mat-menu>
          <input
            type="text"
            (change)="search($event)"
            (keyup.enter)="search($event)"
            placeholder="Type to search..."
            fxFlex
          />
          <button
            mat-mini-fab
            type="button"
            class="search-btn mat-elevation-z0 text-muted"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayoutAlign="center center" [@.disabled]="true">
          <button
            mat-button
            [routerLink]="['/checkout']"
            class="flex-row-button"
          >
            <mat-icon class="mat-icon-lg">shopping_cart</mat-icon>
            <span
              *ngIf="appService.Data.totalCartCount > 0"
              class="cart-items-count"
              >{{ appService.Data.totalCartCount }}</span
            >
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              fxShow="false"
              fxShow.gt-xs
              class="top-cart"
            >
              <span>Shopping Cart</span>
              <span *ngIf="appService.Data.totalCartCount > 0">
                <bdi
                  >{{ appService.Data.totalCartCount }} item<span
                    *ngIf="appService.Data.totalCartCount > 1"
                    >s</span
                  >
                  - $<span>{{
                    appService.Data.totalPrice | number : "1.2-2"
                  }}</span>
                </bdi>
              </span>
            </div>
            <mat-icon class="mat-icon-sm">arrow_drop_down</mat-icon>
          </button>
          <mat-menu
            #cartMenu="matMenu"
            [overlapTrigger]="false"
            xPosition="before"
            class="top-menu-dropdown cart-dropdown"
          >
            <ng-template matMenuContent>
              <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="text-muted"
                >
                  <b
                    ><span
                      >{{ appService.Data.totalCartCount }} ITEM<span
                        *ngIf="appService.Data.totalCartCount > 1"
                        >S</span
                      ></span
                    ></b
                  >
                  <b
                    ><a [routerLink]="['/cart']" class="text-muted"
                      >VIEW CART</a
                    ></b
                  >
                </div>
                <div class="divider mt-1"></div>
                <div
                  *ngIf="appService.Data.totalCartCount == 0"
                  class="py-1 text-muted text-center"
                >
                  You have no items in your shopping cart.
                </div>
                <mat-list>
                  <mat-list-item
                    *ngFor="let product of appService.Data.cartList"
                  >
                    <h3 matLine class="text-muted">{{ product.name }}</h3>
                    <p matLine class="text-muted">
                      <bdi
                        >{{ product.quantity }} x RM{{
                          product.unitPrice | number : "1.2-2"
                        }}</bdi
                      >
                    </p>
                    <img [src]="product?.productDto?.images[0]?.small" alt="image" />
                    <button
                      mat-icon-button
                      color="warn"
                      class="remove"
                      (click)="remove(product); stopClickPropagate($event)"
                      matTooltip="Remove"
                      matTooltipPosition="before"
                    >
                      <mat-icon class="mat-icon-sm">close</mat-icon>
                    </button>
                  </mat-list-item>
                </mat-list>
                <div
                  *ngIf="appService.Data.cartList.length > 0"
                  class="cart-dropdown-footer"
                >
                  <div class="divider mt-1"></div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    class="text-muted mt-1"
                  >
                    <b>TOTAL:</b>
                    <b class="new-price"
                      >RM{{ appService.Data.totalPrice | number : "1.2-2" }}</b
                    >
                  </div>
                  <div class="divider mt-1"></div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    class="mt-1"
                  >
                    <button
                      mat-mini-fab
                      color="warn"
                      (click)="clear(); stopClickPropagate($event)"
                      matTooltip="Clear All"
                      matTooltipPosition="after"
                    >
                      <mat-icon>remove_shopping_cart</mat-icon>
                    </button>
                    <a
                      [routerLink]="['/checkout']"
                      mat-mini-fab
                      color="primary"
                      matTooltip="Checkout"
                      matTooltipPosition="before"
                    >
                      <mat-icon>check</mat-icon>
                    </a>
                  </div>
                </div>
              </span>
            </ng-template>
          </mat-menu>
        </div>
        <div
          fxLayoutAlign="center center"
          fxHide="false"
          fxHide.gt-sm
          [@.disabled]="true"
        >
          <button
            mat-icon-button
            [matMenuTriggerFor]="searchMenu"
            #searchMenuTrigger="matMenuTrigger"
            class="search-toggle-btn"
          >
            <mat-icon class="mat-icon-lg">search</mat-icon>
          </button>
          <mat-menu
            #searchMenu="matMenu"
            [overlapTrigger]="false"
            xPosition="before"
            class="search-dropdown mat-elevation-z8"
          >
            <ng-template matMenuContent>
              <form method="get" fxFlex class="search-form">
                <button
                  mat-raised-button
                  [matMenuTriggerFor]="categories2Menu"
                  #categories2MenuTrigger="matMenuTrigger"
                  type="button"
                  class="mat-elevation-z0 categories text-muted"
                  (click)="stopClickPropagate($event)"
                >
                  {{ category?.name }}<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu
                  #categories2Menu="matMenu"
                  [overlapTrigger]="false"
                  xPosition="before"
                  class="categories-dropdown"
                >
                  <ng-template matMenuContent>
                    <span (mouseleave)="categories2MenuTrigger.closeMenu()">
                      <app-category-list
                        [categories]="categories"
                        [categoryParentId]="0"
                        (change)="changeCategory($event)"
                      ></app-category-list>
                    </span>
                  </ng-template>
                </mat-menu>
                <input
                  type="text"
                  placeholder="Type to search..."
                  fxFlex
                  (click)="stopClickPropagate($event)"
                />
                <button
                  mat-mini-fab
                  (click)="search()"
                  type="button"
                  class="search-btn mat-elevation-z0 text-muted"
                >
                  <mat-icon>search</mat-icon>
                </button>
              </form>
            </ng-template>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <app-menu fxShow="false" fxShow.gt-sm></app-menu> -->

  <div class="theme-container main">
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <router-outlet></router-outlet>
  </div>

  <!-- <app-options></app-options> -->

  <!-- <app-footer></app-footer> -->

  <div
    *ngIf="showBackToTop"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="back-to-top transition"
    (click)="scrollToTop()"
  >
    <mat-icon>arrow_upward</mat-icon>
  </div>

  <div
    *ngIf="showBackToTop"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="topRightCart transition"
  >
    <button
      mat-button
      [matMenuTriggerFor]="cartMenu"
      #cartMenuTrigger="matMenuTrigger"
      class="flex-row-button"
      [routerLink]="['/checkout']"
    >
      <mat-icon class="mat-icon-lg">shopping_cart</mat-icon>
      <span
        *ngIf="appService.Data.totalCartCount > 0"
        class="cart-items-count"
        >{{ appService.Data.totalCartCount }}</span
      >
    </button>
  </div>
</mat-sidenav-container>
