import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { AppService } from "src/app/app.service";
import { CartDto, ProductDto } from "src/shared/service-proxies/service-proxies";

@Component({
  selector: "app-cart-popup",
  templateUrl: "./cart-popup.component.html",
  styleUrls: ["./cart-popup.component.scss"],
})
export class CartPopupComponent implements OnInit {
  total = [];
  grandTotal = 0;
  cartItemCount = [];
  cartItemCountTotal = 0;


  CartTable = new MatTableDataSource();

  CartTableColumn = [
    // {
    //   name: "No",
    //   dataKey: "col1",
    // },
    {
      name: "Name",
      dataKey: "col2",
    },
    // {
    //   name: "Price",
    //   dataKey: "col3",
    // },
    // {
    //   name: "Quantity",
    //   dataKey: "col4",
    // },
    {
      name: "Total",
      dataKey: "col5",
      width: "40px",
    },
  ];


  constructor(
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<CartPopupComponent>
  ) { }

  ngOnInit() {
    this.appService.Data.cartList.forEach((product) => {
      this.total[product.productId] = product.quantity * product.unitPrice;
      this.grandTotal += product.quantity * product.unitPrice;
      this.cartItemCount[product.productId] = product.quantity;
      this.cartItemCountTotal += product.quantity;
    });
    this.updateCustomerTable();
  }

  public updateCart(value) {
    if (value) {
      this.total[value.productId] = value.total;
      this.cartItemCount[value.productId] = value.soldQuantity;
      this.grandTotal = 0;
      this.total.forEach((price) => {
        this.grandTotal += price;
      });
      this.cartItemCountTotal = 0;
      this.cartItemCount.forEach((count) => {
        this.cartItemCountTotal += count;
      });

      this.appService.Data.totalPrice = this.grandTotal;
      this.appService.Data.totalCartCount = this.cartItemCountTotal;

      // this.appService.Data.cartList.forEach(product=>{
      //   this.cartItemCount.forEach((count,index)=>{
      //     if(product.productId == index){
      //       product.quantity = count;
      //     }
      //   });
      // });
    }
  }

  updateCustomerTable() {
    this.CartTable.data = [];
    var i = 0;
    var totalPrice = 0;
    this.appService.Data.cartList.forEach((eachItem: CartDto) => {
      i = i + 1;
      var newObject = {
        id: eachItem.id,
        col1: i,
        col2: eachItem.name + " x " + eachItem.quantity,
        // col3: eachItem.price,
        // col4: eachItem.quantity,
        col5: (eachItem.unitPrice * eachItem.quantity).toFixed(2),
        object: eachItem,
      };
      totalPrice += (eachItem.unitPrice * eachItem.quantity);
      this.CartTable.data.push(newObject);
    });

    var lastObject = {
      col2: "Total",
      col5: totalPrice.toFixed(2)
    };
    this.CartTable.data.push(lastObject);

    this.CartTable.data = this.CartTable.data;
  }

  public remove(product: CartDto) {
    const index: number = this.appService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.appService.Data.cartList.splice(index, 1);
      this.grandTotal = this.grandTotal - this.total[product.productId];
      this.appService.Data.totalPrice = this.grandTotal;
      this.total.forEach((val) => {
        if (val == this.total[product.productId]) {
          this.total[product.productId] = 0;
        }
      });

      this.cartItemCountTotal =
        this.cartItemCountTotal - this.cartItemCount[product.productId];
      this.appService.Data.totalCartCount = this.cartItemCountTotal;
      this.cartItemCount.forEach((val) => {
        if (val == this.cartItemCount[product.productId]) {
          this.cartItemCount[product.productId] = 0;
        }
      });
      this.appService.resetProductCartCount(product);
    }
  }

  public clear() {
    this.appService.Data.cartList.forEach((product) => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }

  closeFunction() {
    this.dialogRef.close();
  }

  checkoutFunction() {
    this.dialogRef.close(true);
  
  }

  deleteItem($event) {
    console.log($event)
    var productObj :CartDto = $event.object;
    var deleteIndex = this.appService.Data.cartList.indexOf($event.object)

    this.cartItemCountTotal =
    this.cartItemCountTotal - this.cartItemCount[productObj.id];
  this.appService.Data.totalCartCount = this.cartItemCountTotal;
  this.cartItemCount.forEach((val) => {
    if (val == this.cartItemCount[productObj.id]) {
      this.cartItemCount[productObj.id] = 0;
    }
  });
    this.appService.Data.cartList.splice(deleteIndex, 1);
    this.updateCustomerTable();
    this.appService.resetProductCartCount(this.appService.Data.cartList[deleteIndex]);

  }
}

export interface DialogData {
  isPos: boolean;
}
