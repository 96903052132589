<ng-container *ngIf="isLoading == true">
  <mat-spinner style="margin: 0 auto"></mat-spinner>
</ng-container>

<ng-container *ngIf="isLoading == false">
  <div class="mb-2">
    <label class="text-center w-100"
      >Please enter your email address to <br />
      reset password</label
    >
  </div>
  <mat-dialog-content>
    <div class="w-100">
      <div class="row justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email Address</mat-label>
            <input
              matInput
              [value]="selectedEmail"
              (change)="emailInputChange($event)"
            />
            <mat-error>* required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row justify-content-center" *ngIf="showEmailError == true">
        <div class="col">
          <p>Error : Email Doesn't Exist</p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!-- instead of your </div> -->
  <div mat-dialog-actions class="row justify-content-center mb-3">
    <button
      mat-raised-button
      class="col-5 ml-2"
      type="button"
      color="primary"
      (click)="addFunction()"
    >
      Send Email
    </button>
  </div>
</ng-container>
