import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TenantServiceProxy, UserServiceProxy } from 'src/shared/service-proxies/service-proxies';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isLoading = false;


  selectedEmail = "";
  selectedTenantId = 1;
  showEmailError = false;
  email = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private _tenantService: TenantServiceProxy,
    public snackBar: MatSnackBar,
    private _userService: UserServiceProxy,
    private _router: Router
  ) {
  }

  ngOnInit(): void { }

  addFunction() {
    console.log(this.selectedEmail)

    if (this.selectedEmail) {
      this.isLoading = true;
      this._tenantService
        .sendEmailToResetEmail(this.selectedEmail, 1, this.myData.merchantUrl)
        .subscribe((result) => {
          if (result == true) {
            this.isLoading = false;
            this.dialogRef.close();
            this.snackBar.open("Email Sent!", "×", {
              panelClass: "success",
              verticalPosition: "top",
              duration: 3000,
            });

          } else {
            this.isLoading = false;
            this.dialogRef.close();
            this.snackBar.open("Email doesn't exist", "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          }

        });
    }
  }

  closeFunction() {
    this.dialogRef.close();
  }

  emailInputChange($event) {
    this.selectedEmail = $event.target.value;
    // this.isLoading = true;
    // this._tenantService
    //   .getTenantsFromEmail(this.selectedEmail)
    //   .subscribe((result) => {
    //     this.isLoading = false;

    //     if (result && result.length > 0) {
    //       this.showEmailError = false;
    //       this.tenantList = result;
    //     } else {
    //       this.showEmailError = true;
    //       this.tenantList = [];
    //     }
    //   });
  }
}

export interface DialogData {
  merchantUrl: string;
}